export default class CharacterGenerator {
  private nextCharCode = 65;

  constructor(firstCharacterCode = 65) {
    this.nextCharCode = firstCharacterCode;
  }

  public nextChar() {
    return String.fromCharCode(this.nextCharCode++)
  }
}