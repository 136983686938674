import Net, { IQuery } from "../../../Net";
import { IPostQuestionsCreateData } from "./Post";

export interface IQuestion extends IPostQuestionsCreateData {
  uuid: string;
}

export interface IGetQuestionsData {
  uuidClass?: string;
  uuidSubject?: string;
}

export default class GetQuestions {
  public async all(data: IGetQuestionsData): Promise<Array<IQuestion>> {
    const query: IQuery = {}
    if (data.uuidClass) query["uuidClass"] = data.uuidClass;
    if (data.uuidSubject) query["uuidSubject"] = data.uuidSubject;

    try {
      const res = await Net.get({
        url: "/examination/questions",
        query
      }) as any;
      return res.success
    } catch (error) {
      throw error;
    }
  }
}