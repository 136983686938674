import Net from "../../../Net";

export interface IPostSectionsCreateData {
  section: string;
  uuidClass: string;
}

export default class PostSections {
  public async create(data: IPostSectionsCreateData) {
    try {
      const res = await Net.post({
        url: "/academics/sections",
        body: {
          uuidClass: data.uuidClass,
          section: data.section
        }
      }) as any;
      return res.success
    } catch (error) {
      throw error;
    }
  }
}