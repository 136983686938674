import React, { useEffect, useState } from 'react';
import moment from "moment";
import { useHistory, useParams } from 'react-router-dom';
import Form from '../../../../../../components/core/Form';
import H3 from '../../../../../../components/core/h3';
import InputSubmit from '../../../../../../components/core/InputSubmit';
import InputText from '../../../../../../components/core/InputText';
import Card from '../../../../../../components/layout/Card';
import Requests from '../../../../../../net/Requests';

import Loading from '../../../../../../components/core/Loading';
import InputDate from '../../../../../../components/core/InputDate';
import InputDateTime from '../../../../../../components/core/InputDateTime';
import { Moment } from 'moment';

function ExaminationScheduledEditPage() {
  const { uuidSchedule } = useParams<{uuidClass: string, uuidSubject: string, uuidSchedule: string}>();
  const history = useHistory();
  
  const [ loading, setLoading ] = useState(true)
  const [ startAt, setStartAt ] = useState<Moment>();
  const [ endAt, setEndAt ] = useState<Moment>();
  
  useEffect(() => {
    (async function () {
      const schd = await Requests.examination.schedule.get().one({ uuid: uuidSchedule })
      setStartAt(moment(schd.startAt))
      setEndAt(moment(schd.endAt))
      setLoading(false)
    })()
  }, [])

  const updateSections = async () => {
    if (startAt && endAt) {
      setLoading(true)
      await Requests.examination.schedule.put().update({
        uuid: uuidSchedule,
        startAt: startAt.toDate(),
        endAt: endAt.toDate(),
      });
  
      history.goBack()
    }
  }

  const header = <>
    <H3>Edit Schedule</H3>
  </>

  return (
    <Card header={header} style={{ padding: 12 }}>
      {loading? (
        <Loading />
      ): (
        <Form>
          <InputDateTime value={startAt} onChange={setStartAt} />
          <InputDateTime value={endAt} onChange={setEndAt} />
          <InputSubmit loading={loading} onClick={updateSections} title="UPDATE" />
        </Form>
      )}
    </Card>
  );
}

export default ExaminationScheduledEditPage;
