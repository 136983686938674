import envJson from "../.env.json"


export interface IEnv {
  variant: string
  apiBaseUrl: string
  editorUrl: string
}

if (envJson.variant !== process.env.NODE_ENV) {
  throw new Error("Invalid environment")
}

const Env: IEnv = {
  variant: envJson.variant,
  apiBaseUrl: envJson.apiBaseUrl,
  editorUrl: envJson.editorUrl
}

export default Env