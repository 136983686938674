import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import DatePicker from "react-date-picker";

export interface IProps {
  placeholder?: string
  style?: CSSProperties
  value?: Date
  onChange(value: Date): void
}

const Container = styled.div`
  display: block;
`

const StyledInput = styled.div`
  display: flex;
  flex-basis: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px 24px;
  margin: 12px 0;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 6px;
`

function InputDate({ style, placeholder, value, onChange }: IProps) {
  return <Container>
    <StyledInput>
      <DatePicker className="date-picker-style-container" value={value} onChange={(date) => onChange(date as Date)} />
    </StyledInput>
  </Container>
}

export default InputDate;
