import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../../components/core/PrivateRoute';
import ExaminationScheduleClassesRouter from './Classes/ExaminationScheduleClassesRouter';

function ExaminationScheduleRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute path={`${path}/classes`} children={<ExaminationScheduleClassesRouter />} />
    </Switch>
  );
}

export default ExaminationScheduleRouter;
