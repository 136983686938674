import React, { useEffect, useState } from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import Button from '../../../../../../../components/core/Button';
import H3 from '../../../../../../../components/core/h3';
import List from '../../../../../../../components/core/List';
import ListItem from '../../../../../../../components/core/ListItems';
import Loading from '../../../../../../../components/core/Loading';
import P from '../../../../../../../components/core/P';
import Card from '../../../../../../../components/layout/Card';
import Requests from '../../../../../../../net/Requests';
import { GetSessionAllResponse } from '../../../../../../../net/Requests/Examination/Sessions/Get';
import { IStudent } from '../../../../../../../net/Requests/Users/Students/Get';

function ExaminationResultsStudentsPage() {
  const { url } = useRouteMatch();
  const { uuidSchedule } = useParams<{uuidSchedule: string}>();
  
  const [ loading, setLoading ] = useState<boolean>(true)
  const [ students, setStudents ] = useState<GetSessionAllResponse[]>([])
  
  useEffect(() => {
    (async function () {
      const respStudents = await Requests.examination.sessions.get().all({ uuidSchedule })
      setStudents(respStudents)
      setLoading(false)
    })()
  }, []);
  
  const studentsView = students && students.length? (
    students.map((item: GetSessionAllResponse) => {
      return <ListItem key={item.session.uuid} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <P>{item.student.rollNumber} - {item.student.name}</P>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <Link to={`${url}/${item.session.uuid}/view`}>
            <Button style={{ margin: '6px'}} title="VIEW" />
          </Link>
        </div>
    </ListItem>})
  ): <p>Not Found</p>

  return (
    <Card header={
      <>
        <H3>Students {students.length}</H3>
      </>
    }>
      <List>
        {studentsView}
        {loading? (
          <ListItem>
            <Loading />
          </ListItem>
        ): <></> }
      </List>
    </Card>
  );
}

export default ExaminationResultsStudentsPage;
