import Net from "../../../Net";

export interface IPostSubjectsCreateData {
  subject: string;
  uuidClass: string;
}

export default class PostSubjects {
  public async create(data: IPostSubjectsCreateData) {
    try {
      const res = await Net.post({
        url: "/academics/subjects",
        body: {
          uuidClass: data.uuidClass,
          subject: data.subject
        }
      }) as any;
      return res.success
    } catch (error) {
      throw error;
    }
  }
}