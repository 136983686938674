import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../../../../../components/core/PrivateRoute';
import ExaminationResultsScheduledPage from './ExaminationResultsScheduledPage';
import ExaminationResultsStudentsRouter from './Students/ExaminationResultsStudentsRouter';

function ExaminationResultsScheduledRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <ExaminationResultsScheduledPage />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:uuidSchedule/students`}>
        <ExaminationResultsStudentsRouter />
      </PrivateRoute>
    </Switch>
  );
}

export default ExaminationResultsScheduledRouter;
