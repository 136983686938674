import React, { useEffect, useState } from 'react';
import { v1 as uuidV1 } from "uuid";
import { useHistory, useParams } from 'react-router-dom';
import Form from '../../../../../components/core/Form';
import H3 from '../../../../../components/core/h3';
import InputSubmit from '../../../../../components/core/InputSubmit';
import P from '../../../../../components/core/P';
import Requests from '../../../../../net/Requests';
import Dropdown, { Item } from '../../../../../components/core/Dropdown';
import InputNumber from '../../../../../components/core/InputNumer';
import Card from '../../../../../components/layout/Card';
import CharacterGenerator from '../../../../../utils/CharacterGenerator';
import Colors from '../../../../../constants/Colors';
import Env from '../../../../../constants/Env';
import Net from '../../../../../net/Net';

declare const window: any;

export enum QuestionType {
  SINGLE_CORRECT = "SINGLE_CORRECT",
  DESCRIPTIVE = "DESCRIPTIVE",
}

interface ISelectAnswerOptions {
  uuid: string;
  option: string;
}

function CreateQuestionPage() {
  const charGenerator = new CharacterGenerator();

  const { uuidClass, uuidSubject } = useParams<{uuidClass: string, uuidSubject: string}>()

  const [ loading ] = useState(false)
  const [ duration, setDuration ] = useState<string>("")
  const [ selected, setSelected ] = useState<Array<string>>([])
  const [ questionType, setQuestionType ] = useState<QuestionType>()
  const [ options ] = useState<Array<ISelectAnswerOptions>>([{
    uuid: uuidV1(),
    option: charGenerator.nextChar()
  }, {
    uuid: uuidV1(),
    option: charGenerator.nextChar()
  }, {
    uuid: uuidV1(),
    option: charGenerator.nextChar()
  }, {
    uuid: uuidV1(),
    option: charGenerator.nextChar()
  }])

  const history = useHistory()

  useEffect(() => {
    if (!document.querySelector("#editorSCript")) {
      const script = document.createElement("script");
      script.src = Env.editorUrl; // https://ekaisoft.sgp1.digitaloceanspaces.com/ckeditor.js
      script.id="editorSCript"
      script.async = true;
      script.onload = function() {
        const scc = getEditorInitScript();
        const sccc = document.createElement("script");
        sccc.text = scc;
        document.body.appendChild(sccc);  
      };
      document.body.appendChild(script);
    } else {
      initEditor();
    }
  }, [])

  const getEditorInitScript = () => {
    const headers: any = {}
    const accessToken = Net.getAccess()
    if (accessToken) {
      headers["Token"] = accessToken
    }
    return `
    window.initEditor = function() {
      window.editor = {}
      InlineEditor.create( document.querySelector( '#editorQuestion' ), {
        simpleUpload: {
          uploadUrl: "${Net.buildUrl("/assets/images")}",
          headers: ${JSON.stringify(headers)}
        },
      } )
      .then( editor => {
        window.editor.question = editor;
      } )
      .catch( error => {
        console.error( 'There was a problem initializing the editor.', error );
      } );

      InlineEditor.create( document.querySelector( '#editorOptionA' ), {
        simpleUpload: {
          uploadUrl: "${Net.buildUrl("/assets/images")}",
          headers: ${JSON.stringify(headers)}
        },
      } )
      .then( editor => {
        window.editor.optionA = editor;
      } )
      .catch( error => {
        console.error( 'There was a problem initializing the editor.', error );
      } );

      InlineEditor.create( document.querySelector( '#editorOptionB' ), {
        simpleUpload: {
          uploadUrl: "${Net.buildUrl("/assets/images")}",
          headers: ${JSON.stringify(headers)}
        },
      } )
      .then( editor => {
        window.editor.optionB = editor;
      } )
      .catch( error => {
        console.error( 'There was a problem initializing the editor.', error );
      } );

      InlineEditor.create( document.querySelector( '#editorOptionC' ), {
        simpleUpload: {
          uploadUrl: "${Net.buildUrl("/assets/images")}",
          headers: ${JSON.stringify(headers)}
        },
      } )
      .then( editor => {
        window.editor.optionC = editor;
      } )
      .catch( error => {
        console.error( 'There was a problem initializing the editor.', error );
      } );

      InlineEditor.create( document.querySelector( '#editorOptionD' ), {
        simpleUpload: {
          uploadUrl: "${Net.buildUrl("/assets/images")}",
          headers: ${JSON.stringify(headers)}
        },
      } )
      .then( editor => {
        window.editor.optionD = editor;
      } )
      .catch( error => {
        console.error( 'There was a problem initializing the editor.', error );
      } );
    }
    window.initEditor();
    `
  }

  const initEditor = () => {
    window.initEditor();
  }

  const onAnswerChange = (selected: Array<string>) => {
    setSelected(selected);
  }

  const onQuestionTypeChange = (selected: Array<string>) => {
    setQuestionType(selected[0] as QuestionType);
  }

  const header = <>
    <H3>Question</H3>
  </>

  return (
    <Card header={header}>

      <P style={{
        background: Colors.BORDER,
        margin: '24px 0 0 0',
        padding: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
      }}>Question</P>
      <div style={{
        border: `1px solid ${Colors.BORDER}`,
        margin: '0',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
      }} >
        <div id="editorQuestion"></div>
      </div>

      <P style={{
        background: Colors.BORDER,
        margin: '24px 0 0 0',
        padding: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
      }}>Question Type</P>
      <Dropdown<{key: QuestionType, value: string}> placeholder="Select Question Type" style={{
        margin: '0 0',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
      }}
      data={[{
        key: QuestionType.SINGLE_CORRECT,
        value: "Single Correct"
      }, {
        key: QuestionType.DESCRIPTIVE,
        value: "Descriptive"
      }]}
      onChange={onQuestionTypeChange}
      renderItem={(item) => {
        return <Item key={item.key} value={item.key} placeholder={`${item.value}`} />
      }} />

      {options.map(opt => {
        return <div key={opt.uuid} style={{
          display: questionType === QuestionType.SINGLE_CORRECT? "block": "none",
        }}>
          <P style={{
            background: Colors.BORDER,
            margin: '24px 0 0 0',
            padding: '6px',
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
          }}>Option {opt.option}</P>
          <div style={{
            border: `1px solid ${Colors.BORDER}`,
            margin: '0',
            borderBottomLeftRadius: '6px',
            borderBottomRightRadius: '6px',
          }} >
            <div id={`editorOption${opt.option}`}></div>
          </div>
        </div>
      })}

      <P style={{
        background: Colors.BORDER,
        margin: '24px 0 0 0',
        padding: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        display: questionType === QuestionType.SINGLE_CORRECT? "flex": "none",
      }}>Select Answer</P>

      <Dropdown<ISelectAnswerOptions> placeholder="Select Correct Option" style={{
        margin: '0 0',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        display: questionType === QuestionType.SINGLE_CORRECT? "flex": "none",
      }}
      data={options}
      onChange={onAnswerChange}
      renderItem={(item) => {
        return <Item key={item.uuid} value={item.uuid} placeholder={`Option ${item.option}`} />
      }} />
      
      <P style={{
        background: Colors.BORDER,
        margin: '24px 0 0 0',
        padding: '6px',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
      }}>Duration (in seconds)</P>
      <InputNumber placeholder="Duration" value={duration} onChange={setDuration} style={{
        margin: '0',
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
      }} />

      <Form>
        <InputSubmit loading={loading} title="ADD" onClick={ async () => {
          const question = window.editor.question.getData();

          const questionData: any = {
            question: question,
            uuidClass: uuidClass,
            uuidSubject: uuidSubject,
            questionType: questionType,
            duration: Number(duration),
            options: []
          }
          if (questionType === QuestionType.SINGLE_CORRECT) {
            const optionA = window.editor.optionA.getData()
            const optionB = window.editor.optionB.getData()
            const optionC = window.editor.optionC.getData()
            const optionD = window.editor.optionD.getData()
            questionData.options = [{
              uuid: options[0].uuid,
              option: optionA,
              correct: selected[0] === options[0].uuid
            }, {
              uuid: options[1].uuid,
              option: optionB,
              correct: selected[0] === options[1].uuid
            }, {
              uuid: options[2].uuid,
              option: optionC,
              correct: selected[0] === options[2].uuid
            }, {
              uuid: options[3].uuid,
              option: optionD,
              correct: selected[0] === options[3].uuid
            }]
          }
          await Requests.examination.questions.post().create(questionData)

          history.goBack();
        }} />
      </Form>
    </Card>
  );
}

export default CreateQuestionPage;
