import { IAssetModel } from "../../../../models/asset";
import Net, { IQuery } from "../../../Net";
import { IClass } from "../../Academics/Classes/Get";
import { ISection } from "../../Academics/Sections/Get";
import { ISubject } from "../../Academics/Subjects/Get";
import { Gender } from "./Post";

export interface IStudent {
  uuid: string;
  name: string;
  email?: string
  gender?: Gender
  rollNumber: string
  dateOfBirth?: Date
  phoneNumber?: string;
  class?: IClass;
  section?: ISection;
  subjects?: Array<ISubject>
}

export interface IGetStudentResponse {
  student: IStudent
  profileImage: IAssetModel
}

export interface IGetStudentsData {
  uuidClass?: string;
  uuidSection?: string;
}

export interface IGetSingleStudentsData {
  uuidStudent: string;
}

export default class GetStudents {
  public async all(data: IGetStudentsData): Promise<Array<IStudent>> {
    const query: IQuery = {}
    if (data.uuidClass) query["uuidClass"] = data.uuidClass;
    if (data.uuidSection) query["uuidSection"] = data.uuidSection;

    try {
      const res = await Net.get({
        url: "/users/students",
        query
      }) as any;
      return res
    } catch (error) {
      throw error;
    }
  }

  public async single(data: IGetSingleStudentsData): Promise<IGetStudentResponse> {
    try {
      const res = await Net.get({
        url: `/users/students/${data.uuidStudent}`,
      }) as any;
      console.log(res)
      return {
        ...res,
        student: {
          ...res.student,
          dateOfBirth: res.student.dateOfBirth? new Date(res.student.dateOfBirth): undefined
        }
      }
    } catch (error) {
      throw error;
    }
  }
}