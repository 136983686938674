import React, { useEffect, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import Button from '../../../../components/core/Button'
import List from '../../../../components/core/List'
import ListItem from '../../../../components/core/ListItems'
import P from '../../../../components/core/P'
import Requests from '../../../../net/Requests'
import { IClass } from '../../../../net/Requests/Academics/Classes/Get'
import Card from '../../../../components/layout/Card'
import Loading from '../../../../components/core/Loading'
import H3 from '../../../../components/core/h3'

export interface IProps {
  flagCanAdd?: boolean
  flagSections?: boolean
  flagSubjects?: boolean
}


function ClassesPage({ flagCanAdd = false, flagSections = false, flagSubjects = false }: IProps) {
  const { path, url } = useRouteMatch()

  const [ loading, setLoading ] = useState<boolean>(true)
  const [ classes, setClasses ] = useState<IClass[]>([])
  
  useEffect(() => {
    (async function () {
      const classes = await Requests.academics.classes.get().all()
      setClasses(classes)
      setLoading(false)
    })()
  }, []);

  const classView = classes? (
    classes.map((item) => {
      return <ListItem key={item.uuid} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <P>Class - {item.class}</P>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            
            { flagSections? (
              <Link to={`${path}/${item.uuid}/sections`}>
                <Button style={{ margin: '6px 12px 6px 0'}} title="Sections" />
              </Link>
            ): <></> }
            { flagSubjects? (
              <Link to={`${path}/${item.uuid}/subjects`}>
                <Button style={{ margin: '6px 12px 6px 0'}} title="Subjects" />
              </Link>
            ): <></> }

            {/* <Link to={`${path}/${item.uuid}/students`}>
              <Button style={{ margin: '6px'}} title="Students" />
            </Link> */}
          </div>
        </ListItem>
    })
  ): ( <p>Not Found</p> )
  return (
    <Card header={
      <>
        <H3>Classes</H3>
        {flagCanAdd? (
          <Link to={ `${url}/add`} style={{ margin: 12 }}>
            <Button title="Add Class" />
          </Link>
        ): <></> }
      </>
    }>
      <List>
        {classView}
        {loading? (
          <ListItem>
            <Loading />
          </ListItem>
        ): <></> }
      </List>
    </Card>
  );
}

export default ClassesPage;
