import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../../../components/core/PrivateRoute';
import ExaminationResultsClassesPage from './ExaminationResultsClassesPage';
import ExaminationResultsSubjectsRouter from './Subjects/ExaminationResultsSubjectsRouter';

function ExaminationResultsRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <ExaminationResultsClassesPage />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:uuidClass/subjects`} children={<ExaminationResultsSubjectsRouter />} />
    </Switch>
  );
}

export default ExaminationResultsRouter;
