import Net, { IQuery } from "../../../Net";

export interface ISubject {
  uuid: string;
  uuidClass: String;
  subject: string;
}

export interface IGetSubjectsData {
  uuidClass?: string;
}

export default class GetSubjects {
  
  public async all(data: IGetSubjectsData): Promise<Array<ISubject>> {
    const query: IQuery = {}
    if (data.uuidClass) {
      query.uuidClass = data.uuidClass;
    }
    try {
      const res = await Net.get({
        url: "/academics/subjects",
        query
      }) as any;
      return res.success
    } catch (error) {
      throw error;
    }
  }
}