import Net from "../../../Net";

export interface IClass {
  uuid: string;
  class: string;
}

export default class GetClasses {
  
  public async all(): Promise<Array<IClass>> {
    try {
      const res = await Net.get({
        url: "/academics/classes"
      }) as any;
      return res.success
    } catch (error) {
      throw error;
    }
  }
}