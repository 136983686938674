import Env from "../constants/Env";

export interface IBody {
  [key: string]: any;
}

export interface IHeaders {
  [key: string]: any;
}

export interface IQuery extends IBody {}

export interface IGetParams {
  url: string;
  query?: IQuery;
}

export interface IPostParams extends IGetParams {
  body?: IBody;
}

export interface IPatchParams extends IPostParams {}

export interface IDeleteParams extends IPostParams {}

export default class Net {
  public static BASE_URL = Env.apiBaseUrl;
  private static ACCESS_TOKEN: string | null = null;

  public static setAccess(token: string) {
    this.ACCESS_TOKEN = token;
  }

  public static getAccess() {
    return this.ACCESS_TOKEN;
  }

  public static async get({ url, query }: IGetParams) {
    const queryString = this.generateQueryString(query);
    const headers: IHeaders = {}
    if (this.ACCESS_TOKEN) {
      headers["Token"] = this.ACCESS_TOKEN;
    }

    try {
      const response = await fetch(`${this.BASE_URL}${url}${queryString}`, {
        method: 'get',
        headers
      })
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  public static async post({ url, query, body }: IPostParams) {
    const queryString = this.generateQueryString(query);
    const headers: IHeaders = {
      "Content-Type": "application/json"
    }
    if (this.ACCESS_TOKEN) {
      headers["Token"] = this.ACCESS_TOKEN;
    }

    try {
      const response = await fetch(`${this.BASE_URL}${url}${queryString}`, {
        method: 'post',
        headers,
        body: JSON.stringify(body)
      })
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  public static async postMultipart({ url, query, body }: IPostParams) {
    const queryString = this.generateQueryString(query);
    const headers: IHeaders = {}
    if (this.ACCESS_TOKEN) {
      headers["Token"] = this.ACCESS_TOKEN;
    }

    try {
      const response = await fetch(`${this.BASE_URL}${url}${queryString}`, {
        method: 'post',
        headers,
        body: body as any
      })
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  public static async put({ url, query, body }: IPatchParams) {
    const queryString = this.generateQueryString(query);
    const headers: IHeaders = {
      "Content-Type": "application/json"
    }
    if (this.ACCESS_TOKEN) {
      headers["Token"] = this.ACCESS_TOKEN;
    }

    try {
      const response = await fetch(`${this.BASE_URL}${url}${queryString}`, {
        method: 'put',
        headers,
        body: JSON.stringify(body)
      })
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  public static async delete({ url, query, body }: IDeleteParams) {
    const queryString = this.generateQueryString(query);
    const headers: IHeaders = {
      "Content-Type": "application/json"
    }
    if (this.ACCESS_TOKEN) {
      headers["Token"] = this.ACCESS_TOKEN;
    }

    try {
      const response = await fetch(`${this.BASE_URL}${url}${queryString}`, {
        method: 'delete',
        headers,
        body: JSON.stringify(body)
      })
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  public static buildUrl(url: string, query?: IQuery) {
    const queryString = this.generateQueryString(query)
    return `${this.BASE_URL}${url}${queryString}`
  }

  private static generateQueryString(query?: IQuery) {
    return query? `?${Object.keys(query).map(key => `${key}=${query[key]}`).join("&")}`: ''
  }
}