import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../../components/core/PrivateRoute';
import ExaminationClassesPage from './ExaminationClassesPage';
import ExaminationSubjectsRouter from './Subjects/ExaminationSubjectsRouter';

function ExaminationClassesRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <ExaminationClassesPage />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:uuidClass/subjects`} children={<ExaminationSubjectsRouter />} />
    </Switch>
  );
}

export default ExaminationClassesRouter;
