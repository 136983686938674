import Net from "../../../Net";

// export interface IQuestionOptions {
//   uuid: string
//   option: string
//   correct: boolean
// }

export interface IPostScheduleCreateData {
  uuidClass: string
  uuidSubject: string
  uuidStudents: Array<string>,
  startAt: Date
  endAt: Date
  allStudents: boolean
  freedomLevel: number
}

export default class PostSchedule {
  public async create(data: IPostScheduleCreateData) {
    try {
      const res = await Net.post({
        url: "/examination/schedule",
        body: {
          uuidClass: data.uuidClass,
          uuidSubject: data.uuidSubject,
          uuidStudents: data.uuidStudents,
          startAt: data.startAt,
          endAt: data.endAt,
          allStudents: data.allStudents,
          freedomLevel: data.freedomLevel
        }
      }) as any;
      return res.success
    } catch (error) {
      throw error;
    }
  }
}