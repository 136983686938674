import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../components/core/PrivateRoute';
import CommunicationManagementPage from './CommunicationManagementPage';
import NoticesRouter from './notices/NoticesRouter';
import MessagingRouter from './messaging/MessagingRouter';

function CommunicationManagementRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={`${path}`} children={<CommunicationManagementPage />} />
      <PrivateRoute path={`${path}/notices`} children={<NoticesRouter />} />
      <PrivateRoute path={`${path}/messaging`} children={<MessagingRouter />} />
    </Switch>
  );
}

export default CommunicationManagementRouter;