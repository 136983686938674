import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import Colors from '../../constants/Colors';
import { ReactComponent as SvgIcon } from "../../svg/delete.svg";

export interface IProps {
  style?: CSSProperties
  fill?: string
  fillOpacity?: string
  hoverColor?: string
  onClick?(): any
}

interface IWrapper {
  hoverColor: string
}

const Wrapper = styled.div<IWrapper>`
  cursor: pointer;
  &:hover svg {
    fill: ${props => props.hoverColor};
  }
`

function SvgDelete({ style, fill=Colors.DANGER, fillOpacity , hoverColor=Colors.DANGER, onClick }: IProps) {
  return (
    <Wrapper style={style} hoverColor={hoverColor} onClick={onClick}>
      <SvgIcon fill={fill} fillOpacity={fillOpacity}  />
    </Wrapper>
  );
}

export default SvgDelete;
