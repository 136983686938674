import Net from "../../../Net";

export interface IQuestionOptions {
  uuid: string
  option: string
  correct: boolean
}

export interface IPostQuestionsCreateData {
  uuidClass: string
  uuidSubject: string
  question: string
  questionType: string
  duration: number
  options: Array<IQuestionOptions>
}

export default class PostQuestions {
  public async create(data: IPostQuestionsCreateData) {
    try {
      const res = await Net.post({
        url: "/examination/questions",
        body: {
          uuidClass: data.uuidClass,
          uuidSubject: data.uuidSubject,
          question: data.question,
          questionType: data.questionType,
          duration: data.duration,
          options: data.options
        }
      }) as any;
      return res.success
    } catch (error) {
      throw error;
    }
  }
}