import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../../components/core/Button';
import H3 from '../../../components/core/h3';
import List from '../../../components/core/List';
import ListItem from '../../../components/core/ListItems';
import Loading from '../../../components/core/Loading';
import P from '../../../components/core/P';
import Card from '../../../components/layout/Card';
import Requests from '../../../net/Requests';
import { INoticeResponse } from '../../../net/Requests/Notices/Get';


function NoticesPage() {
  const { url } = useRouteMatch();

  const [ loading, setLoading ] = useState(true)
  const [ notices, setNotices ] = useState<Array<INoticeResponse>>([])

  useEffect(() => {
    (async function () {
      const noticeList = await Requests.notices.get().get({ limit: 100, skip: 0 });
      setNotices(noticeList)
      setLoading(false)
    })()
  }, []);

  function onClickDelete(uuid: string) {

  }

  const header = <>
    <H3>Notices</H3>
    <Link to={ `${url}/add`} style={{ margin: 12 }}>
      <Button title="Add Notice" />
    </Link>
  </>

  const noticesView = loading && notices && notices.length === 0? <></>: (
    notices === null || notices.length === 0? (<P>No notices found</P>): (
      <List>
        {notices.map( item => {
          return <ListItem key={item.notice.uuid}>
            <H3>{item.notice.title}</H3>
            <P>{item.notice.description}</P>
            <div style={{ marginBottom: 12 }}>
              <Button href={`${url}/${item.notice.uuid}/delete`} title='Delete Notice'/>
            </div>
          </ListItem>
        })}
      </List>
    )
  )

  return (
    <Card header={header} >
      {noticesView}
      {loading? <Loading />: <></>}
    </Card>
  );
}

export default NoticesPage;
