import Net, { IQuery } from "../../../Net";
import { IStudent } from "../../Users/Students/Get";

export interface IGetScheduleData {
  uuidSchedule: string
}

export interface IGetSingleScheduleData {
  uuid: string
}

export interface ISession {
  uuid: string
  uuidUser: string
  uuidSchedule: string
  startAt: Date
  endAt: Date
  freedomLevel: number
}

export interface GetSessionAllResponse {
  student: IStudent
  session: ISession
}

export default class GetSessions {

  public async all(data: IGetScheduleData): Promise<Array<GetSessionAllResponse>> {
    const query: IQuery = {}
    if (data.uuidSchedule) query["uuidSchedule"] = data.uuidSchedule;

    try {
      const res = await Net.get({
        url: "/examination/session",
        query
      }) as any;
      const finalRes = res.success?.map((item: GetSessionAllResponse) => ({
        ...item,
      }))
      return finalRes
    } catch (error) {
      throw error;
    }
  }
}