import React from 'react'
import { v1 as uuidV1 } from "uuid";
import { Switch, useRouteMatch } from 'react-router-dom'
import PrivateRoute from '../../../../../../components/core/PrivateRoute'
import CreateStudentsPage from './CreateStudentsPage';
import StudentsPage from './StudentsPage';
import EditStudentsPage from './EditStudentsPage';

function StudentsRouter() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <PrivateRoute exact path={path} children={<StudentsPage />} />
      <PrivateRoute path={`${path}/add`} children={<CreateStudentsPage />} />
      <PrivateRoute path={`${path}/:uuidStudent/edit`} key={uuidV1()} children={<EditStudentsPage />} />
    </Switch>
  );
}

export default StudentsRouter;
