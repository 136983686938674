import Net from "../../../Net";

export interface IPostLoginData {
  username: string;
  password: string;
}

export default class PostAuthLogin {
  public async create(data: IPostLoginData) {
    try {
      const res = await Net.post({
        url: "/auth/login",
        body: {
          username: data.username,
          password: data.password
        }
      }) as any;
      return res.success
    } catch (error) {
      throw error;
    }
  }
}