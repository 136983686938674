import React, { useEffect, useState } from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import Button from '../../../../../../components/core/Button';
import H3 from '../../../../../../components/core/h3';
import List from '../../../../../../components/core/List';
import ListItem from '../../../../../../components/core/ListItems';
import Loading from '../../../../../../components/core/Loading';
import P from '../../../../../../components/core/P';
import Card from '../../../../../../components/layout/Card';
import Requests from '../../../../../../net/Requests';
import { IStudent } from '../../../../../../net/Requests/Users/Students/Get';

function StudentsPage() {
  const { url } = useRouteMatch();
  const { uuidClass, uuidSection } = useParams<{uuidClass: string, uuidSection: string}>();
  
  const [ loading, setLoading ] = useState<boolean>(true)
  const [ students, setStudents ] = useState<IStudent[]>([])
  
  useEffect(() => {
    (async function () {
      const respStudents = await Requests.users.students.get().all({ uuidClass, uuidSection })
      setStudents(respStudents)
      setLoading(false)
    })()
  }, []);
  
  const studentsView = students && students.length? (
    students.map((item: IStudent) => {
      return <ListItem key={item.uuid} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <P>{item.rollNumber} - {item.name}</P>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <Link to={`${url}/${item.uuid}/edit`}>
            <Button style={{ margin: '6px'}} title="EDIT" />
          </Link>
        </div>
    </ListItem>})
  ): <p>Not Found</p>

  return (
    <Card header={
      <>
        <H3>Students</H3>
        <Link to={ `${url}/add`} style={{ margin: 12 }}>
          <Button title="Add Student" />
        </Link>
      </>
    }>
      <List>
        {studentsView}
        {loading? (
          <ListItem>
            <Loading />
          </ListItem>
        ): <></> }
      </List>
    </Card>
  );
}

export default StudentsPage;
