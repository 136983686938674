import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import Dimens from '../../constants/Dimens';
import Fonts from '../../constants/Font';

export interface IProps {
  style?: CSSProperties;
  children: ReactNode;
}

const Wrapper = styled.h4`
display: flex;
margin: ${Dimens.X2}px 0;
font-size: ${Dimens.FONT_H4}px;
font-weight: ${Dimens.FONT_WEIGHT_600};
line-height: 1;
font-family: ${Fonts.PRIMARY};
`

function H4({ style, children }: IProps) {
  return (
    <Wrapper style={style} >{children}</Wrapper>
  );
}

export default H4;
