import Net, { IQuery } from "../../../Net";

export interface ISection {
  uuid: string;
  uuidClass: String;
  section: string;
}

export interface IGetSectionsData {
  uuidClass?: string;
}

export default class GetSections {
  
  public async all(data: IGetSectionsData): Promise<Array<ISection>> {
    const query: IQuery = {}
    if (data.uuidClass) {
      query.uuidClass = data.uuidClass;
    }
    try {
      const res = await Net.get({
        url: "/academics/sections",
        query
      }) as any;
      return res.success
    } catch (error) {
      throw error;
    }
  }
}