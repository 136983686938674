import React, { useState } from 'react';
import { Moment } from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import Form from '../../../../../../components/core/Form';
import H3 from '../../../../../../components/core/h3';
import InputSubmit from '../../../../../../components/core/InputSubmit';
import Card from '../../../../../../components/layout/Card';
import Requests from '../../../../../../net/Requests';

import InputCheckbox from '../../../../../../components/core/InputCheckbox';
import InputDateTime from '../../../../../../components/core/InputDateTime';



function ExaminationScheduledAddPage() {
  const { uuidClass, uuidSubject } = useParams<{uuidClass: string, uuidSubject: string}>();
  const history = useHistory();
  
  const [ loading, setLoading ] = useState(false)
  const [ allStudents, setAllStudents ] = useState(false)
  const [ freedomLevel, setFreedomLevel ] = useState(1)
  const [ startAt, setStartAt ] = useState<Moment>();
  const [ endAt, setEndAt ] = useState<Moment>();
  
  const createSections = async () => {
    if (startAt && endAt) {
      setLoading(true)
      await Requests.examination.schedule.post().create({
        uuidClass,
        uuidSubject,
        startAt: startAt.toDate(),
        endAt: endAt.toDate(),
        allStudents: true,
        uuidStudents: [],
        freedomLevel,
      });
      history.goBack()
    }
  }

  const onChangeFreedomLevel = (free: boolean) => {
    setFreedomLevel(free? 10: 1)
  }

  const header = <>
    <H3>Add Schedule</H3>
  </>

  return (
    <Card header={header} style={{ padding: 12 }}>
      <Form>
        <InputDateTime value={startAt} onChange={setStartAt} />
        <InputDateTime value={endAt} onChange={setEndAt} />

        <InputCheckbox checked={freedomLevel !== 1} onChange={onChangeFreedomLevel} title="Freedom Level - Relaxed" />
        
        <InputSubmit loading={loading} onClick={createSections} title="ADD" />
      </Form>
    </Card>
  );
}

export default ExaminationScheduledAddPage;
