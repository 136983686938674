import Net, { IQuery } from "../../../Net";
import { IQuestion } from "./Get";

export interface IDeleteQuestionsData {
  uuid: string
}

export default class DeleteQuestions {
  public async one(data: IDeleteQuestionsData): Promise<Array<IQuestion>> {
    try {
      const res = await Net.delete({
        url: "/examination/questions",
        body: {
          uuid: data.uuid
        }
      }) as any;
      return res.success
    } catch (error) {
      throw error;
    }
  }
}