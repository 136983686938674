import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import Colors from '../../constants/Colors';

export interface IProps {
  style?: CSSProperties;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
`

const AvatarContainer = styled.div`
  border: ${Colors.BORDER};
  border-raidus: 200px;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  cursor: pointer;
`

function HeaderProfile({ style }: IProps) {
  return (
    <Wrapper style={style}>
      <AvatarContainer>

      </AvatarContainer>
    </Wrapper>
  );
}

export default HeaderProfile;
