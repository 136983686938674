import { useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import Button from "../../../components/core/Button";
import H3 from "../../../components/core/h3";
import List from "../../../components/core/List";
import ListItem from "../../../components/core/ListItems";
import Loading from "../../../components/core/Loading";
import P from "../../../components/core/P";
import Card from "../../../components/layout/Card";
import Colors from "../../../constants/Colors";
import Dimens from "../../../constants/Dimens";
import Requests from "../../../net/Requests";
import {
  ApprovalStatus,
  IMessageTemplate,
} from "../../../net/Requests/Messages/templates/Get";

function MessagingTemplatesPage() {
  const { url } = useRouteMatch();

  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState<Array<IMessageTemplate>>([]);

  useEffect(() => {
    (async function () {
      const templateList = await Requests.messages.templates
        .get()
        .get({ limit: 100, skip: 0 });
      setTemplates(templateList || []);
      setLoading(false);
    })();
  }, []);

  const header = (
    <>
      <H3>Communication</H3>
      <Link to={`${url}/add`} style={{ margin: 12 }}>
        <Button title="Add Template" />
      </Link>
    </>
  );

  const templatesView =
    loading && templates.length === 0 ? (
      <></>
    ) : templates.length === 0 ? (
      <P>No templates found</P>
    ) : (
      <List>
        {templates.map((item) => {
          let statusView;
          if (item.approvalStatus === ApprovalStatus.PENDING) {
            statusView = (
              <P
                style={{
                  background: Colors.ACCENT,
                  color: "#fff",
                  borderRadius: Dimens.X,
                  padding: `${Dimens.X}px ${Dimens.X2}px`,
                  margin: 0,
                  alignSelf: "center",
                }}
              >
                Pending
              </P>
            );
          } else if (item.approvalStatus === ApprovalStatus.APPROVED) {
            statusView = (
              <P
                style={{
                  background: Colors.ACCENT,
                  color: "#fff",
                  borderRadius: Dimens.X,
                  padding: `${Dimens.X}px ${Dimens.X2}px`,
                  margin: 0,
                  alignSelf: "center",
                }}
              >
                Approved
              </P>
            );
          } else if (item.approvalStatus === ApprovalStatus.REJECTED) {
            statusView = (
              <P
                style={{
                  background: Colors.ACCENT,
                  color: "#fff",
                  borderRadius: Dimens.X,
                  padding: `${Dimens.X}px ${Dimens.X2}px`,
                  margin: 0,
                  alignSelf: "center",
                }}
              >
                Rejected
              </P>
            );
          }
          return (
            <ListItem key={item.templateId}>
              <div style={{}}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <P>{item.templateName}</P>
                  {/* {statusView} */}
                </div>
                <P>{item.templateText}</P>
                <Button
                  // disabled={item.approvalStatus !== ApprovalStatus.APPROVED}
                  href={`${url}/${item.templateId}/send`}
                  title="Send Message"
                  styleContainer={{ width: "100%" }}
                />
              </div>
            </ListItem>
          );
        })}
      </List>
    );

  return (
    <Card header={header}>
      {templatesView}
      {loading ? <Loading /> : <></>}
    </Card>
  );
}

export default MessagingTemplatesPage;
