import React, { CSSProperties } from 'react';
import styled from 'styled-components';

export interface IProps {
  placeholder?: string
  style?: CSSProperties
  value: string
  onChange(value: string): any
  onClick?(): any
  multiline?: boolean
  multilineRows?: number
}

const StyledInput = styled.input`
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px 24px;
  margin: 12px 0;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 6px;
`

const StyledTextArea = styled.textarea`
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px 24px;
  margin: 12px 0;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 6px;
  resize: none;
`

function InputText({ style, placeholder, value, onChange, onClick=() => undefined, multiline=false, multilineRows=6 }: IProps) {
  if (multiline) {
    return <StyledTextArea rows={multilineRows} style={style} placeholder={placeholder} value={value} onChange={e => onChange(e.target.value)} onClick={onClick} />
  } else {
    return <StyledInput type="text" style={style} placeholder={placeholder} value={value} onChange={e => onChange(e.target.value)} onClick={onClick} />
  }
}

export default InputText;
