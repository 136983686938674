import React, { CSSProperties, ReactElement, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import Colors from '../../constants/Colors';
import Dimens from '../../constants/Dimens';
import Fonts from '../../constants/Font';
import Loading from './Loading';
import P from './P';

export interface ISelected {
  key: string
  placeholder: string
}

export interface IProps<T> {
  style?: CSSProperties
  placeholder: ReactNode
  data: Array<T> | null
  selected?: Array<ISelected>
  multiselect?: boolean
  resetKey?: string
  onChange?(selected: Array<string>): any
  renderItem(item: T, index: number): ReactNode
}

interface IListWrapperProps {
  open: boolean;
}

interface IListContainerProps {
  open: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  border: 1px solid ${Colors.BORDER};
  font-family: ${Fonts.PRIMARY};
  flex-direction: column;
  position: relative;
  cursor: pointer;
`

const PlaceholderWrapper = styled.p`
  font-size: ${Dimens.FONT_P}px;
  line-height: 1;
  color: ${Colors.TEXT_LIGHT};
  padding: ${Dimens.X2}px ${Dimens.X4}px;
  margin: 0;
  cursor: pointer;
`

const ListWrapper = styled.div<IListWrapperProps>`
  position: relative;
  z-index: 100;
  border-radius: ${Dimens.BORDER_RAD_X}px;
  height: ${props => props.open? "auto": "0px" };
  display: ${props => props.open? "block": "none" };
`

const ListContainer = styled.ul<IListContainerProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  top: 6px;
  background: #fff;
  border: 1px solid ${Colors.BORDER};
  height: ${props => props.open? "auto": "0px" };
  overflow: auto;
  max-height: 200px;
  margin: 0;
  padding: 0;
  border-radius: ${Dimens.BORDER_RAD_X}px;
  overflow: auto;
  box-shadow: ${Dimens.SHADOW_X};
`

function Dropdown<T>({ style, placeholder, selected, resetKey, renderItem, multiselect, data, onChange= () => undefined }: IProps<T>) {

  const [ open, setOpen ] = useState<boolean>(false)
  const [ newSelected, setNewSelected ] = useState<Array<ISelected>>([])
  
  const toggle = () => setOpen(!open)

  switch (typeof placeholder) {
    case 'string':
      placeholder = <PlaceholderWrapper onClick={toggle}>{placeholder}</PlaceholderWrapper>
      break;
    default:
      break;
  }

  const selectItem = (key: string, placeholder: string) => {
    if (!multiselect) setOpen(false)
    if (multiselect) {
      setNewSelected(newSelected.concat([{key, placeholder}]))
    } else {
      setNewSelected([{key, placeholder}])
    }
  }

  const removeItem = (key?: string) => {
    if (multiselect) {
      setNewSelected(newSelected.filter(i => i.key !== key))
    } else {
      setNewSelected([])
    }
  }

  useEffect(() => {
    onChange(newSelected.map(i => i.key))
  }, [ newSelected ]);

  useEffect(() => {
    if (selected) {
      setNewSelected(selected)
    } else {
      removeItem()
    }
  }, [ resetKey ]);

  const children = data === null? <Loading />: data?.map((item, index) => {
    const child: ReactElement = renderItem(item, index) as ReactElement;
    if (!child.key && child.props) {
      throw new Error("Key should be a string")
    }
    const key = child.key as string
    const placeholder = child.props.placeholder as string;
    const selected = newSelected.findIndex(i => i.key === key) !== -1
    return <div key={key} style={{ background: selected? Colors.BORDER: "#fff"}} onClick={() => {
      if (selected && multiselect) {
        removeItem(key)
      } else {
        selectItem(key, placeholder)
      }
    }}>{child}</div>
  })

  const selectedItems = newSelected.length? (
    <>
      {multiselect? (
        <div onClick={toggle} style={{ display: 'inline-flex' }}>
          {newSelected.map(i => <P key={i.key} style={{display: 'inline', background: Colors.BORDER, margin: '6px', padding: '6px', borderRadius: Dimens.BORDER_RAD_X }}>{i.placeholder}</P>)}
        </div>
      ): (
        <P onClick={toggle} style={multiselect? {display: 'inline-flex'}: {}}>{newSelected[0].placeholder}</P>
      )}
    </>
  ): <></>

  return (
    <Wrapper style={style} >
      {newSelected.length? selectedItems: placeholder}
      <ListWrapper open={open}>
        <ListContainer open={open}>
          {children}
        </ListContainer>
      </ListWrapper>
    </Wrapper>
  );
}

export default Dropdown;


export interface IPropsItem {
  key?: string;
  style?: CSSProperties
  children?: ReactNode
  placeholder?: ReactNode
  value: string
}

const ItemWrapper = styled.li`
  border-bottom: 1px solid ${Colors.BORDER};
  cursor: pointer;
`

function Item({ placeholder }: IPropsItem) {

  return (
    <ItemWrapper>
      <P>{placeholder}</P>
    </ItemWrapper>
  )
}

export { Item }