import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../components/core/PrivateRoute';
import ExaminationClassesRouter from './Classes/ExaminationClassesRouter';
import ExaminationPage from './ExaminationPage';
import ExaminationResultsRouter from './Results/ExaminationResultsRouter';
import ExaminationScheduleRouter from './Schedule/ExaminationScheduleRouter';

function ExaminationManagementRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <ExaminationPage />
      </PrivateRoute>
      <PrivateRoute path={`${path}/classes`}>
        <ExaminationClassesRouter />
      </PrivateRoute>
      <PrivateRoute path={`${path}/schedule`}>
        <ExaminationScheduleRouter />
      </PrivateRoute>
      <PrivateRoute path={`${path}/results`}>
        <ExaminationResultsRouter />
      </PrivateRoute>
    </Switch>
  );
}

export default ExaminationManagementRouter;
