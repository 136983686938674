import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../../../../components/core/PrivateRoute';
import ExaminationScheduleSubjectsPage from './ExaminationScheduleSubjectsPage';
import ExaminationScheduledRouter from './Schedule/ExaminationScheduledRouter';

function ExaminationScheduleSubjectsRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <ExaminationScheduleSubjectsPage />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:uuidSubject/scheduled`} children={<ExaminationScheduledRouter />} />
    </Switch>
  );
}

export default ExaminationScheduleSubjectsRouter;
