import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import H3 from '../../../../../../components/core/h3';
import Loading from '../../../../../../components/core/Loading';
import Card from '../../../../../../components/layout/Card';
import ProfileImage from '../../../../../../components/userManagement/ProfileImage';
import StudentDetailsInput, { IStudentData } from '../../../../../../components/userManagement/StudentDetailsInput';
import Requests from '../../../../../../net/Requests';
import { IGetStudentResponse } from '../../../../../../net/Requests/Users/Students/Get';

function EditStudentsPage() {
  const history = useHistory();
  const { uuidStudent } = useParams<{uuidClass: string, uuidSection: string, uuidStudent: string}>();

  const [ loading, setLoading ] = useState(true)
  const [ student, setStudent ] = useState<IGetStudentResponse | undefined>(undefined)

  useEffect(() => {
    (async function () {
      const respStudents = await Requests.users.students.get().single({ uuidStudent })
      setStudent(respStudents)
      setLoading(false)
    })()
  }, []);

  const updateStudent = async (student: IStudentData) => {
    await Requests.users.students.put().update({
      uuid: uuidStudent,
      name: student.name,
      phoneNumber: student.phoneNumber,
      uuidClass: student.uuidClass,
      uuidSection: student.uuidSection,
      gender: student.gender,
      email: student.email,
      rollNumber: student.rollNumber,
      dateOfBirth: student.dateOfBirth,
      uuidSubjects: student.subjects
    });
    history.goBack()
  }

  const header = <>
    <H3>Edit Student</H3>
  </>

  return (
    <Card header={header}>
      {loading? ( <Loading /> ): (
        <>
          {student?.student && <ProfileImage student={student?.student} profileImage={student?.profileImage} />}
          <StudentDetailsInput submitTitle="UPDATE" data={student?.student} onClick={updateStudent} fields={{ CLASS: true, SECTION: true }}  />
        </>
      )}
    </Card>
  );
}

export default EditStudentsPage;
