import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../../components/core/PrivateRoute';
import NoticeAddPage from './NoticeAddPage';
import NoticeDeletePage from './NoticeDeletePage';
import NoticesPage from './NoticesPage';


function NoticesRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path} children={<NoticesPage />} />
      <PrivateRoute path={`${path}/add`} children={<NoticeAddPage />} />
      <PrivateRoute path={`${path}/:uuidNotice/delete`} children={<NoticeDeletePage />} />
    </Switch>
  );
}

export default NoticesRouter;
