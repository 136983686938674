import React, { CSSProperties } from 'react';
import styled from 'styled-components';

export interface IProps {
  placeholder?: string;
  style?: CSSProperties;
  onChange(value: File|null): any;
}

const StyledInput = styled.input`
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px 24px;
  margin: 12px 0;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 6px;
`

function InputFile({ style, placeholder, onChange }: IProps) {
  return (
    <StyledInput
      type="file"
      style={style}
      placeholder={placeholder}
      onChange={e => e.target.files? onChange(e.target.files[0]): undefined}
    />
  );
}

export default InputFile;
