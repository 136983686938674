import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../../../components/core/PrivateRoute';
import ExaminationScheduleClassesPage from './ExamincationScheduleClassesPage';
import ExaminationScheduleSubjectsRouter from './subjects/ExaminationScheduleSubjectsRouter';

function ExaminationScheduleClassesRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <ExaminationScheduleClassesPage />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:uuidClass/subjects`} children={<ExaminationScheduleSubjectsRouter />} />
    </Switch>
  );
}

export default ExaminationScheduleClassesRouter;
