import React, { useEffect, useState } from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import Button from '../../../../../../../components/core/Button';
import H3 from '../../../../../../../components/core/h3';
import H4 from '../../../../../../../components/core/h4';
import List from '../../../../../../../components/core/List';
import ListItem from '../../../../../../../components/core/ListItems';
import Loading from '../../../../../../../components/core/Loading';
import P from '../../../../../../../components/core/P';
import Card from '../../../../../../../components/layout/Card';
import Colors from '../../../../../../../constants/Colors';
import Dimens from '../../../../../../../constants/Dimens';
import Requests from '../../../../../../../net/Requests';
import { IResponseGetResult } from '../../../../../../../net/Requests/Examination/Result/Get';
import { GetSessionAllResponse } from '../../../../../../../net/Requests/Examination/Sessions/Get';
import { IStudent } from '../../../../../../../net/Requests/Users/Students/Get';
import { QuestionType } from '../../../../../Classes/Subjects/Questions/CreateQuestionPage';

function ExaminationResultsStudentsSessionPage() {
  const { url } = useRouteMatch();
  const { uuidSession } = useParams<{uuidSession: string}>();
  
  const [ loading, setLoading ] = useState<boolean>(true)
  const [ result, setResult ] = useState<IResponseGetResult>()
  
  useEffect(() => {
    (async function () {
      const respStudents = await Requests.examination.results.get().one({ uuidSession })
      console.log("respStudents", respStudents)
      setResult(respStudents)
      setLoading(false)
    })()
  }, []);

  const view = result? <List>
    {result.answers.map(answer => <div key={answer.question.uuid}>
      <ListItem>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <H4 style={{
          margin: '24px 0 12px 0'
        }}>Question</H4>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}>

          <P style={{ padding: "6px", color: "#fff", background: Colors.ACCENT, borderRadius: Dimens.BORDER_RAD_X }}>{answer.question?.duration} sec</P>
        </div>
      </div>
      <span style={{
        fontSize: 12,
        fontWeight: 700,
        color: Colors.PRIMARY_LIGHT
      }}>{answer.question?.questionType}</span>
      <div dangerouslySetInnerHTML={{ __html: answer.question?.question }}></div>
      {answer.question?.questionType === QuestionType.SINGLE_CORRECT? (
        <>
          <H4 style={{
            margin: '12px 0'
          }}>Options</H4>
          <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
            {answer.question?.options.map((opt, index) => {
              // console.log(answer.session?.answer && opt.uuid === answer.session?.answer[0], opt.uuid, answer.session?.answer)
                return <li key={opt.uuid} style={{
                  display: 'flex',
                  flexDirection: 'row',
                  margin: '12px 0',
                  borderRadius: Dimens.BORDER_RAD_X,
                  border: answer.session?.answer && opt.uuid === answer.session?.answer[0]? `4px solid ${Colors.ACCENT_LIGHT}`: "",
                  background: opt.correct? Colors.PRIMARY_LIGHT: Colors.BORDER
                }}>
                  <P style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    margin: 0,
                    padding: 12
                  }}>{index + 1 }</P>
                  <div dangerouslySetInnerHTML={{ __html: opt.option }}></div>
                </li>
              })}
          </ul>
        </>
      ): (
        <></>
      )}
      <List style={{ background: Colors.WARNING_LIGHT, borderRadius: Dimens.BORDER_RAD_X, marginBottom: Dimens.X2 }}>
        <ListItem style={{ flexDirection: 'row', justifyContent: 'space-between'}}>
          <P>Sent At</P>
          <P>{answer.session.sentAt.toString().substring(4, 24)}</P>
        </ListItem>
        <ListItem style={{ flexDirection: 'row', justifyContent: 'space-between', borderBottom: 0 }}>
          <P>Answered At</P>
          <P>{answer.session.answeredAt.toString().substring(4, 24)}</P>
        </ListItem>
      </List>
    </ListItem>
    </div>)}
  </List>: <></>

  return (
    <Card header={
      <>
        <H3>Result</H3>
        {result? <P><strong>{result.result.correctAnswers}/{result.result.totalQuestions}</strong></P>: <></>}
      </>
    }>
      <List>
        {result? <List style={{ background: Colors.BORDER, borderRadius: Dimens.BORDER_RAD_X, marginTop: Dimens.X2 }}>
          <ListItem style={{ flexDirection: 'row', justifyContent: 'space-between'}}>
            <P>Name</P>
            <P>{result.student.name}</P>
          </ListItem>
          <ListItem style={{ flexDirection: 'row', justifyContent: 'space-between'}}>
            <P>Start At</P>
            <P>{result.session.startAt.toString().substring(4, 24)}</P>
          </ListItem>
          <ListItem style={{ flexDirection: 'row', justifyContent: 'space-between', borderBottom: 0 }}>
            <P>End At</P>
            <P>{result.session.endAt.toString().substring(4, 24)}</P>
          </ListItem>
        </List>: <></>}
        {view}
        {loading? (
          <ListItem>
            <Loading />
          </ListItem>
        ): <></> }
      </List>
    </Card>
  );
}

export default ExaminationResultsStudentsSessionPage;
