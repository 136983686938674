import React, { CSSProperties, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import Colors from '../../constants/Colors';
import Dimens from '../../constants/Dimens';

export interface IProps {
  href?: string;
  disabled?: boolean;
  style?: CSSProperties;
  styleContainer?: CSSProperties;
  title?: string;
  children?: ReactNode;
  type?: ButtonTypes;
  dark?: boolean; // dark theme or light
}

export enum ButtonTypes { PRIMARY,  }

interface IButtonColors {
  [color: number]: {
    disabled: string
    notDisabled: string
  };
}
const ButtonColors: IButtonColors = {}
ButtonColors[ButtonTypes.PRIMARY] = {
  disabled: Colors.PRIMARY_LIGHT,
  notDisabled: Colors.PRIMARY
};

interface IWrapperProps {
  type: ButtonTypes;
}

const Wrapper = styled.button`
border: 0;
display: inline-block;
padding: 0;
outline: none;
cursor: pointer;
background: transparent;
border-radius: ${Dimens.BORDER_RAD_X}px;
`

const ButtonWrapper = styled.div<IWrapperProps>`
  border: 1px solid ${(props: IWrapperProps) => ButtonColors[props.type]};
  display: inline-block;
  outline: none;
  cursor: pointer;
  background: #fff;
  border-radius: ${Dimens.BORDER_RAD_X}px;
`

const Title = styled.p`
  margin: 0;
  padding: 6px 12px;
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase
`

function Button({ style, styleContainer, title, children, type = ButtonTypes.PRIMARY, dark = true, href, disabled=false }: IProps) {

  const titleStyle: CSSProperties = {}
  titleStyle.color = dark? "#fff": "#222";

  const wrapperStyle: CSSProperties = {}
  wrapperStyle.backgroundColor = disabled? ButtonColors[type].disabled: ButtonColors[type].notDisabled;


  const content = title? <Title style={titleStyle}>{title}</Title>: children;
  const buttonWithoutLink = <Wrapper style={styleContainer}>
    <ButtonWrapper style={{...wrapperStyle, ...style}} type={type}>
      {content}
    </ButtonWrapper>
  </Wrapper>

  return href && !disabled? <Link to={href}>{buttonWithoutLink}</Link>: buttonWithoutLink;
}

export default Button;
