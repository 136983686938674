import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import PrivateRoute from "./components/core/PrivateRoute";
import ProvideAuth from "./components/core/ProvideAuth";
import HomePage from "./pages/Home";
import LoginPage from "./pages/LoginPage";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;

function App() {
  return (
    <ProvideAuth>
      <BrowserRouter>
        <Wrapper>
          <Switch>
            <Route path="/login" exact>
              <LoginPage />
            </Route>
            <PrivateRoute path="/">
              <HomePage />
            </PrivateRoute>
          </Switch>
        </Wrapper>
      </BrowserRouter>
    </ProvideAuth>
  );
}

export default App;
