import { IAssetModel } from "../../../models/asset";
import { INoticesModel } from "../../../models/notices";
import Net from "../../Net";

export interface INoticeResponse {
  notice: INoticesModel,
  asset: IAssetModel
}

export interface IPostNoticesGet {
  limit: number
  skip: number
}

export default class GetNotices {
  public async get(data: IPostNoticesGet) {
    try {
      const res = await Net.get({
        url: "/notices",
        query: {
          "limit": data.limit,
          "skip": data.skip
        }
      }) as any;
      return res.success.notices as Array<INoticeResponse>
    } catch (error) {
      throw error;
    }
  }
}