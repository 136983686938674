import React from 'react';
import { v1 as uuidV1 } from "uuid";
import { Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../../../components/core/PrivateRoute';
import ExaminationSubjectsPage from './ExaminationSubjectsPage';
import ExaminationQuestionsRouter from './Questions/ExaminationQuestionsRouter';

function ExaminationSubjectsRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <ExaminationSubjectsPage />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:uuidSubject/questions`} children={<ExaminationQuestionsRouter />} key={uuidV1()} />
    </Switch>
  );
}

export default ExaminationSubjectsRouter;
