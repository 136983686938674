import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

export interface IProps {
  style?: CSSProperties;
  children: ReactNode;
}

const Wrapper = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-direction: column;
`

function List({ style, children }: IProps) {
  return (
    <Wrapper style={style} >{children}</Wrapper>
  );
}

export default List;