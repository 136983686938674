import Net from "../../../Net";

export interface IPostmessagesTemplatesCreate {
  flowId: string;
  templateName: string;
  templateText: string;
}

export default class PostMessagesTemplates {
  public async create(data: IPostmessagesTemplatesCreate) {
    try {
      const res = (await Net.post({
        url: "/messages/templates",
        body: {
          flowId: data.flowId,
          templateName: data.templateName,
          templateText: data.templateText,
        },
      })) as any;
      console.log(res);
      return res.success;
    } catch (error) {
      throw error;
    }
  }
}
