import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../../components/core/PrivateRoute';
import ExaminationResultsClassesRouter from './Classes/ExaminationResultsClassesRouter';

function ExaminationResultsRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute path={`${path}/classes`} children={<ExaminationResultsClassesRouter />} />
    </Switch>
  );
}

export default ExaminationResultsRouter;
