import Net from "../../../Net";

export interface IPostmessagesMessagesSend {
  flowId: string;
  variables: Record<string, string>;
}

export interface IPostmessagesMessagesSendTest {
  flowId: string;
  variables: Record<string, string>;
  phone: string;
}

export default class PostMessagesMessages {
  public async send(data: IPostmessagesMessagesSend) {
    try {
      const res = (await Net.post({
        url: "/messages",
        body: {
          flowId: data.flowId,
          variables: data.variables,
        },
      })) as any;
      console.log(res);
      return res.success;
    } catch (error) {
      throw error;
    }
  }

  public async sendToPhone(data: IPostmessagesMessagesSendTest) {
    try {
      const res = (await Net.post({
        url: "/messages/phone",
        body: {
          flowId: data.flowId,
          variables: data.variables,
          phone: data.phone,
        },
      })) as any;
      console.log(res);
      return res.success;
    } catch (error) {
      throw error;
    }
  }
}
