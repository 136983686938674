import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import H3 from '../../components/core/h3';
import List from '../../components/core/List';
import ListItem from '../../components/core/ListItems';
import P from '../../components/core/P';
import Card from '../../components/layout/Card';


function ExaminationPage() {
  const { url } = useRouteMatch();

  const header = <>
    <H3>Examination</H3>
  </>

  return (
    <Card header={header} >
      <List>
        {[{
          url: `${url}/classes`,
          title: "Questions"
        }, {
          url: `${url}/schedule/classes`,
          title: "Schedule"
        }, {
          url: `${url}/results/classes`,
          title: "Results"
        }].map( item => <ListItem key={item.url}>
          <Link to={item.url}>
            <P>{item.title}</P>
          </Link>
        </ListItem>)}
      </List>
    </Card>
  );
}

export default ExaminationPage;
