import { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import Form from "../../../components/core/Form";
import H3 from "../../../components/core/h3";
import InputSubmit from "../../../components/core/InputSubmit";
import InputText from "../../../components/core/InputText";
import Loading from "../../../components/core/Loading";
import P from "../../../components/core/P";
import Card from "../../../components/layout/Card";
import Colors from "../../../constants/Colors";
import Dimens from "../../../constants/Dimens";
import Requests from "../../../net/Requests";
import { IMessageTemplate } from "../../../net/Requests/Messages/templates/Get";

function MessagingTemplateSendRouter() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { templateId } = useParams<{ templateId: string }>();

  const [loading, setLoading] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [variables, setVariables] = useState<Array<string>>([]);
  const [allValuesPresent, setAllValuesPresent] = useState(false);
  const [variableValues, setVariableValues] = useState<Record<string, string>>(
    {}
  );
  const [template, setTemplate] = useState<IMessageTemplate>();

  useEffect(() => {
    checkAllValuesPresent(variables, variableValues);
  }, [variables, variableValues]);

  useEffect(() => {
    (async function () {
      const templateList = await Requests.messages.templates
        .get()
        .get({ limit: 100, skip: 0 });
      const templ = templateList.filter((i) => i.templateId === templateId)[0];
      const vars = templ?.templateText.match(/##[a-z-_]+##/gi);
      const values: Record<string, string> = {};
      variables.filter((i) => {
        values[i] = "";
      });

      setVariableValues(values);
      setVariables(vars || []);
      setTemplate(templ);
      setLoading(false);
      console.log("template", template, loading, vars, values);
    })();
  }, []);

  function checkAllValuesPresent(
    variables: Array<string>,
    variableValues: Record<string, string>
  ) {
    console.log("variables, variableValues", variables, variableValues);
    setAllValuesPresent(
      variables.filter((i) => (variableValues[i]?.length || 0) === 0).length ===
        0
    );
    console.log(
      "---------aa-aa-aa-a",
      variableValues,
      variables.filter((i) => (variableValues[i]?.length || 0) === 0)
    );
  }

  async function sendMessages() {
    setLoading(true);
    const variablesFinal: Record<string, string> = {};
    Object.keys(variableValues).map((key) => {
      variablesFinal[key.replaceAll("##", "")] = variableValues[key];
    });

    const sendResponse = await Requests.messages.messages.post().send({
      flowId: templateId,
      variables: variablesFinal,
    });
    console.log("sendResponse", sendResponse);
    setLoading(false);
    history.goBack();
  }

  async function sendMessageToPhone() {
    setLoading(true);
    const variablesFinal: Record<string, string> = {};
    Object.keys(variableValues).map((key) => {
      variablesFinal[key.replaceAll("##", "")] = variableValues[key];
    });

    const sendResponse = await Requests.messages.messages.post().sendToPhone({
      flowId: templateId,
      variables: variablesFinal,
      phone: phoneNumber,
    });
    console.log("sendResponse", sendResponse);
    setLoading(false);
  }

  const header = (
    <>
      <H3>Send Message</H3>
    </>
  );

  return (
    <Card header={header}>
      {loading ? (
        <Loading />
      ) : (
        <Form>
          <ul style={{ listStyle: "none", padding: 0 }}>
            {variables.map((i) => {
              return (
                <li key={i} style={{ fontSize: 12 }}>
                  <p>{i}</p>
                  <InputText
                    style={{ width: "100%" }}
                    value={variableValues[i]}
                    onChange={(val: string) => {
                      setVariableValues({
                        ...variableValues,
                        [i]: val,
                      });
                    }}
                    placeholder={i}
                  />
                </li>
              );
            })}
          </ul>
          <P
            style={{
              background: Colors.WARNING_LIGHT,
              borderRadius: Dimens.BORDER_RAD_X,
              marginTop: Dimens.X2,
              marginBottom: Dimens.X2,
              lineHeight: 1.2,
            }}
          >
            {template?.templateText.replace(
              new RegExp(variables.join("|"), "gi"),
              (matched) => variableValues[matched] || matched
            )}
          </P>

          <div style={{ background: "#f9f9f9" }}>
            <InputText
              style={{ width: "100%" }}
              value={phoneNumber}
              onChange={setPhoneNumber}
              placeholder="Phone Number"
            />
            <InputSubmit
              disabled={!allValuesPresent}
              loading={loading}
              onClick={sendMessageToPhone}
              title={"Send Message To Phone"}
            />
          </div>

          <InputSubmit
            disabled={!allValuesPresent}
            loading={loading}
            onClick={sendMessages}
            title={"Send Message To All"}
          />
        </Form>
      )}
    </Card>
  );
}

export default MessagingTemplateSendRouter;
