import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import Datetime from "react-datetime";
import moment, { Moment } from 'moment';

import "react-datetime/css/react-datetime.css";

export interface IProps {
  placeholder?: string
  style?: CSSProperties
  value?: Moment
  onChange(value: Moment|undefined): void
}

const Container = styled.div`
  display: block;
`

const StyledInput = styled.div`
  display: flex;
  flex-basis: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px 24px;
  margin: 12px 0;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 6px;
`

function InputDateTime({ style, placeholder, value, onChange }: IProps) {
  return <Container>
    <StyledInput>
      <Datetime value={value} onChange={value => moment.isMoment(value)? onChange(value): onChange(undefined)} />
    </StyledInput>
  </Container>
}

export default InputDateTime;
