import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../../../../../../components/core/PrivateRoute';
import ExaminationResultsStudentsPage from './ExaminationResultsStudentsPage';
import ExaminationResultsStudentsSessionPage from './ExaminationResultsStudentsSessionPage';

function ExaminationResultsStudentsRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <ExaminationResultsStudentsPage />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:uuidSession/view`}>
        <ExaminationResultsStudentsSessionPage />
      </PrivateRoute>
    </Switch>
  );
}

export default ExaminationResultsStudentsRouter;
