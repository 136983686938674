import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

export interface IProps {
  style?: CSSProperties
  onClick?(): any
  children: ReactNode
}

const Wrapper = styled.p`
  margin: 0;
  padding: 12px;
  font-size: 16px;
  line-height: 1;
  font-family: 'Montserrat', sans-serif;
`

function P({ style, children, onClick }: IProps) {
  return (
    <Wrapper style={style} onClick={onClick} >{children}</Wrapper>
  );
}

export default P;
