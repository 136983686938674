import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

export interface IProps {
  style?: CSSProperties;
  children: ReactNode;
}

const Wrapper = styled.li`
  display: flex;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  flex-direction: column;
`

function ListItem({ style, children }: IProps) {
  return (
    <Wrapper style={style} >{children}</Wrapper>
  );
}

export default ListItem;