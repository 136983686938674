import Net from "../../../Net";
import { IPostStudentsCreateData } from "./Post";


export interface IPostStudentsUpdateData extends IPostStudentsCreateData {
  uuid: string
}

export default class PutStudents {
  public async update(data: IPostStudentsUpdateData) {
    try {
      const res = await Net.put({
        url: `/users/students/${data.uuid}`,
        body: {
          name: data.name,
          email: data.email,
          gender: data.gender,
          uuidClass: data.uuidClass,
          rollNumber: data.rollNumber,
          phoneNumber: data.phoneNumber,
          dateOfBirth: data.dateOfBirth,
          uuidSection: data.uuidSection,
          uuidSubjects: data.uuidSubjects
        }
      }) as any;
      return res
    } catch (error) {
      throw error;
    }
  }
}