import React, { useEffect, useState } from 'react';

import Requests from '../../net/Requests';
import { IClass } from '../../net/Requests/Academics/Classes/Get';
import Dropdown, { Item } from '../core/Dropdown';


export interface IProps {
  selected?: IClass
  onChange(uuidClass: string): void
}


function DropdownClassSelect({ selected, onChange }: IProps) {
  
  const [ classes, setClasses ] = useState<Array<IClass> | null>(null)

  useEffect(() => {
    (async function () {
      const classes = await Requests.academics.classes.get().all()
      setClasses(classes)
    })()
  }, []);

  const onChangeClass = (classes: Array<string>) => {
    onChange(classes[0])
  }

  return (
    <Dropdown<IClass> placeholder="SELECT CLASS" style={{
      margin: '12px 0',
      borderRadius: '6px',
    }}
    data={classes}
    selected={selected !== undefined? [{ key: selected.uuid, placeholder: `Class - ${selected.class}` }]: undefined}
    onChange={onChangeClass}
    renderItem={(item) => {
      return <Item key={item.uuid} value={item.uuid} placeholder={`Class - ${item.class}`} />
    }} />
  );
}

export default DropdownClassSelect;
