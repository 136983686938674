import React, { useEffect, useState } from 'react';
import Requests from '../../net/Requests';
import { ISubject } from '../../net/Requests/Academics/Subjects/Get';
import Dropdown, { Item } from '../core/Dropdown';


export interface IProps {
  selected?: Array<ISubject>
  uuidClass: string
  onChange(uuidClass: Array<string>): void
}


function DropdownSubjectSelect({ selected, uuidClass, onChange }: IProps) {
  
  const [ subjects, setSubjects ] = useState<Array<ISubject> | null>(null)

  useEffect(() => {
    (async function () {
      const subjData = await Requests.academics.subjects.get().all({ uuidClass })
      setSubjects(subjData)
    })()
  }, [ uuidClass ]);

  const onChangeSubjects = (subjects: Array<string>) => {
    onChange(subjects)
  }

  return (
    <Dropdown<ISubject> placeholder="SELECT SUBJECTS" style={{
      margin: '12px 0',
      borderRadius: '6px',
    }}
    data={subjects}
    selected={selected !== undefined? selected.map(i => ({ key: i.uuid, placeholder: i.subject })): undefined}
    onChange={onChangeSubjects}
    resetKey={uuidClass}
    multiselect={true}
    renderItem={(item) => {
      return <Item key={item.uuid} value={item.uuid} placeholder={item.subject} />
    }} />
  );
}

export default DropdownSubjectSelect;
