import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import Colors from '../../constants/Colors';
import Dimens from '../../constants/Dimens';
import P from './P';

export interface IProps {
  title?: string;
  style?: CSSProperties;
  checked: boolean;
  onChange(value: boolean): any;
}

interface IStyledInputProps {
  checked: boolean
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 12px;
  cursor: pointer;
`


const StyledInput = styled.div<IStyledInputProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: ${props => props.checked? Colors.PRIMARY: Colors.BORDER};
  border-radius: ${Dimens.BORDER_RAD_X}px;
  overflow: hidden;
`

const Checkmark = styled.div`
  display: inline-block;
  transform: rotate(45deg);
  height: 16px;
  width: 12px;
  margin-bottom: 4px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
`

function InputCheckbox({ style, title, checked, onChange }: IProps) {
  return (
    <Wrapper onClick={() => onChange(!checked)}>
      <StyledInput checked={checked} style={style}>
        {checked? <Checkmark />: <></>}
      </StyledInput>
      <P>{title}</P>
    </Wrapper>
  );
}

export default InputCheckbox;
