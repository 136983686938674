import React, { createContext, useContext, useState } from "react";
import { RouteProps } from "react-router-dom";
import Net from "../../net/Net";
import Requests from "../../net/Requests";

export interface ILoginData {
  username: string;
  password: string;
}

export interface IAuthContext {
  user: string | null;
  signin(data: ILoginData, cb: () => void): any;
  signout(cb: () => void): any;
}

const AuthContext = createContext<IAuthContext | null>(null);

const fakeAuth = {
  isAuthenticated: false,
  async signin(data: ILoginData, cb: () => void) {
    const response = await Requests.auth.login.post().create({
      username: data.username,
      password: data.password
    })
    
    if (response) {
      Net.setAccess(response);
      fakeAuth.isAuthenticated = true;
      cb()
    }
  },
  signout(cb: () => void) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  }
};

function useProvideAuth(): {
  user: string | null;
  signin(data: ILoginData, cb: () => void): any;
  signout(cb: () => void): any;
} {
  const [user, setUser] = useState<string | null>(null);

  const signin = (data: ILoginData, cb: () => void) => {
    return fakeAuth.signin(data, () => {
      setUser("user");
      cb();
    });
  };

  const signout = (cb: () => void) => {
    return fakeAuth.signout(() => {
      setUser(null);
      cb();
    });
  };

  return {
    user,
    signin,
    signout
  };
}

function ProvideAuth({ children }: RouteProps) {
  const auth = useProvideAuth();
  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

export default ProvideAuth;