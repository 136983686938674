import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

export interface IProps {
  style?: CSSProperties;
  children: ReactNode;
}

const StyledInput = styled.form`
  display: flex;
  width: 100%;
  padding: 0;
  flex-direction: column;
  margin: 0;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 6px;
`

function Form({ style, children }: IProps) {
  return (
    <StyledInput style={style}>{children}</StyledInput>
  );
}

export default Form;
