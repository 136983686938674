import React from 'react'
import { v1 as uuidV1 } from "uuid"
import { Switch, useRouteMatch } from 'react-router-dom'
import PrivateRoute from '../../../../../components/core/PrivateRoute'
import CreateSectionsPage from './CreateSectionsPage'
import SectionsPage from './SectionsPage'
import StudentsRouter from './Students/StudentsRouter'

function SectionsRouter() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <SectionsPage />
      </PrivateRoute>
      <PrivateRoute path={`${path}/add`}>
        <CreateSectionsPage />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:uuidSection/students`} key={uuidV1()} children={<StudentsRouter />} />
    </Switch>
  )
}

export default SectionsRouter
