import Net, { IQuery } from "../../../Net";
import { IStudent } from "../../Users/Students/Get";
import { IQuestion } from "../Questions/Get";
import { ISession } from "../Sessions/Get";

export interface IGetScheduleData {
  uuidSession: string
}

export interface IAnswers {
  correct: boolean
  question: IQuestion
  session: {
    answeredAt: Date
    answer: Array<string>
    createdAt: Date
    sentAt: Date
    uuid: string
    uuidSession: string
    question: IQuestion
  }
}

export interface IResponseGetResult {
  answers: Array<IAnswers>
  student: IStudent
  session: ISession
  result: {
    correctAnswers: number
    totalQuestions: number
  }
}

export default class GetResults {

  public async one(data: IGetScheduleData): Promise<IResponseGetResult> {
    try {
      const res = await Net.get({
        url: `/examination/results/${data.uuidSession}`,
      }) as any;
      const finalRes: IResponseGetResult = {
        student: res.success?.student,
        answers: res.success?.answers.map((o: IAnswers) => ({
          ...o,
          session: {
            ...o.session,
            answeredAt: o.session.answeredAt? new Date(o.session.answeredAt as any): undefined,
            createdAt: o.session.createdAt? new Date(o.session.createdAt as any): undefined,
            sentAt: o.session.sentAt? new Date(o.session.sentAt as any): undefined,
          }
        })),
        result: res.success?.result,
        session: {
          ...res.success?.session,
          startAt: res.success?.session.startAt? new Date(res.success?.session.startAt as any): undefined,
          endAt: res.success?.session.endAt? new Date(res.success?.session.endAt as any): undefined,
        }
      }
      return finalRes
    } catch (error) {
      throw error;
    }
  }
}