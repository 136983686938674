import React from 'react'
import { v1 as uuidV1 } from "uuid"
import { Switch, useRouteMatch } from 'react-router-dom'
import PrivateRoute from '../../../../components/core/PrivateRoute'
import CreateClassPage from './CreateClassPage'
import StudentsPage from './Sections/SectionsPage'
import ClassesPage from './ClassesPage'
import SubjectsRouter from './Subjects/SubjectsRouter'
import SectionsRouter from './Sections/SectionsRouter'

export interface IProps {
  flagCanAdd?: boolean
  flagSections?: boolean
  flagSubjects?: boolean
}

function ClassesRouter({ flagCanAdd = false, flagSections = false, flagSubjects = false }: IProps) {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <PrivateRoute exact path={path} children={<ClassesPage flagCanAdd={flagCanAdd} flagSections={flagSections} flagSubjects={flagSubjects} />} />
      <PrivateRoute path={`${path}/add`} children={<CreateClassPage />} />
      <PrivateRoute path={`${path}/:uuidClass/sections`} key={uuidV1()} children={<SectionsRouter />} />
      <PrivateRoute path={`${path}/:uuidClass/subjects`} key={uuidV1()} children={<SubjectsRouter flagAddNew />} />
    </Switch>
  );
}

export default ClassesRouter;
