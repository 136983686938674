import React, { useState } from 'react';
import Dropdown, { Item } from '../core/Dropdown';
import Form from '../core/Form';
import H3 from '../core/h3';
import InputSubmit from '../core/InputSubmit';
import InputText from '../core/InputText';
import { Gender } from '../../net/Requests/Users/Students/Post';

import DropdownClassSelect from './DropdownClassSelect';
import { IStudent } from '../../net/Requests/Users/Students/Get';
import DropdownSectionSelect from './DropdownSectionSelect';
import DropdownSubjectSelect from './DropdownSubjectSelect';
import InputDate from '../core/InputDate';

const GendersData = [{
  id: Gender.MALE,
  option: "MALE"
}, {
  id: Gender.FEMALE,
  option: "FEMALE"
}, {
  id: Gender.OTHER,
  option: "OTHER"
}]

export interface IStudentData {
  name: string;
  email?: string
  gender?: Gender
  rollNumber: string
  dateOfBirth?: Date
  phoneNumber?: string;
  uuidClass?: string;
  uuidSection?: string;
  subjects?: Array<string>
}

export interface IProps {
  data?: IStudent
  submitTitle: string
  onClick(student: IStudentData): void
  fields?: {
    CLASS: boolean
    SECTION: boolean
  }
}

interface ISelectGenderOptions {
  id: Gender;
  option: string;
}

function StudentDetailsInput({ data, submitTitle, onClick, fields }: IProps) {
  const [ loading, setLoading ] = useState(false)
  const [ name, setName ] = useState(data?.name? data.name: '')
  const [ phone, setPhone ] = useState(data?.phoneNumber? data.phoneNumber: '')
  const [ email, setEmail ] = useState(data?.email? data.email: '')
  const [ uuidClass, setUuidClass ] = useState(data?.class?.uuid? data.class?.uuid: '')
  const [ uuidSection, setUuidSection ] = useState(data?.section?.uuid? data.section?.uuid: '')
  const [ gender, setGender ] = useState<Gender | undefined>(data?.gender? data.gender: undefined)
  const [ dateOfBirth, setDateOfBirth ] = useState<Date|undefined>(data?.dateOfBirth? data.dateOfBirth: undefined);
  const [ rollNumber, setRollNumber ] = useState<string>(data?.rollNumber? data.rollNumber: '')
  const [ subjects, setSubjects ] = useState<Array<string>>(data?.subjects? data.subjects.map(i => i.uuid): [])

  const onClickSubmit = () => {
    onClick({
      name,
      phoneNumber: phone,
      email,
      gender,
      uuidClass,
      uuidSection,
      dateOfBirth: dateOfBirth as Date,
      rollNumber,
      subjects
    })
  }

  return (
    <Form>
      <InputText placeholder="ROLL NUMBER" value={rollNumber} onChange={setRollNumber} />
      <InputText placeholder="NAME" value={name} onChange={setName} />
      <InputText placeholder="EMAIL" value={email} onChange={setEmail} />
      <InputText placeholder="PHONE" value={phone} onChange={setPhone} />
      
      { fields?.CLASS? <DropdownClassSelect selected={data?.class?.uuid === uuidClass? data?.class: undefined} onChange={setUuidClass} />: <></> }
      { fields?.SECTION? <DropdownSectionSelect selected={data?.section?.uuid === uuidSection? data?.section: undefined} uuidClass={uuidClass} onChange={setUuidSection} />: <></> }

      <DropdownSubjectSelect selected={data?.subjects !== undefined? data?.subjects: undefined} uuidClass={uuidClass} onChange={setSubjects} />

      <Dropdown<ISelectGenderOptions> placeholder="Select Gender" style={{
        margin: '12px 0',
        borderRadius: '6px',
      }}
      selected={data?.gender !== undefined? [{ key: data.gender, placeholder: GendersData.find(i => i.id === data.gender)?.option as string }]: undefined}
      data={GendersData}
      onChange={(selected) => setGender(selected[0] as Gender)}
      renderItem={(item) => {
        return <Item key={item.id} value={item.id} placeholder={`${item.option}`} />
      }} />
      
      <InputDate value={dateOfBirth} onChange={setDateOfBirth} />

      <InputSubmit loading={loading} onClick={onClickSubmit} title={submitTitle} />
    </Form>
  );
}

export default StudentDetailsInput;
