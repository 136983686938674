import Net, { IQuery } from "../../../Net";

export interface ISchedule {
  uuid: string
  uuidClass: string
  uuidSubject: string
  startAt: Date
  endAt: Date
  allStudents: boolean
  freedomLevel: number
}

export interface IGetScheduleData {
  uuidClass?: string
  uuidSubject?: string
}

export interface IGetSingleScheduleData {
  uuid: string
}

export default class GetSchedule {

  public async one(data: IGetSingleScheduleData): Promise<ISchedule> {
    try {
      const res = await Net.get({
        url: `/examination/schedule/${data.uuid}`
      }) as any;
      return {
        ...res.success,
        startAt: res.success.startAt? new Date(res.success.startAt as any): undefined,
        endAt: res.success.endAt? new Date(res.success.endAt as any): undefined
      }
    } catch (error) {
      throw error;
    }
  }

  public async all(data: IGetScheduleData): Promise<Array<ISchedule>> {
    const query: IQuery = {}
    if (data.uuidClass) query["uuidClass"] = data.uuidClass;
    if (data.uuidSubject) query["uuidSubject"] = data.uuidSubject;

    try {
      const res = await Net.get({
        url: "/examination/schedule",
        query
      }) as any;
      const finalRes = res.success?.map((item: ISchedule) => ({
        ...item,
        startAt: item.startAt? new Date(item.startAt as any): undefined,
        endAt: item.endAt? new Date(item.endAt as any): undefined
      }))
      return finalRes
    } catch (error) {
      throw error;
    }
  }
}