import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../../../../../components/core/PrivateRoute';
import ExaminationScheduledAddPage from './ExaminationScheduledAddPage';
import ExaminationScheduledEditPage from './ExaminationScheduledEditPage';
import ExaminationScheduledPage from './ExaminationScheduledPage';

function ExaminationScheduleScheduleRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <ExaminationScheduledPage />
      </PrivateRoute>
      <PrivateRoute path={`${path}/add`}>
        <ExaminationScheduledAddPage />
      </PrivateRoute>
      <PrivateRoute path={`${path}/edit/:uuidSchedule`}>
        <ExaminationScheduledEditPage />
      </PrivateRoute>
    </Switch>
  );
}

export default ExaminationScheduleScheduleRouter;
