import Net from "../../../Net";

export enum Gender {
  MALE = "M",
  FEMALE = "F",
  OTHER = "O"
}

export interface IPostStudentProfileImage {
  uuid: string
  profileImage: File
}

export interface IPostStudentsCreateData {
  name: string
  email?: string
  gender?: Gender
  uuidClass?: string
  rollNumber: string
  phoneNumber?: string
  dateOfBirth?: Date
  uuidSection?: string
  uuidSubjects?: Array<string>
}

export default class PostStudents {
  public async create(data: IPostStudentsCreateData) {
    try {
      const res = await Net.post({
        url: "/users/students",
        body: {
          name: data.name,
          email: data.email,
          gender: data.gender,
          uuidClass: data.uuidClass,
          rollNumber: data.rollNumber,
          phoneNumber: data.phoneNumber,
          dateOfBirth: data.dateOfBirth,
          uuidSection: data.uuidSection,
          uuidSubjects: data.uuidSubjects
        }
      }) as any;
      return res.success
    } catch (error) {
      throw error;
    }
  }

  public async profileImage(data: IPostStudentProfileImage) {
    try {
      const body = new FormData()
      body.append("file", data.profileImage)

      const res = await Net.postMultipart({
        url: `/users/students/profile-image/${data.uuid}`,
        body,
      }) as any;
      return res.success.profileImage.primaryUrl
    } catch (error) {
      throw error;
    }
  }
}