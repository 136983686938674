import React from 'react';
import styled from 'styled-components';
import HeaderProfile from '../core/HeaderProfile';
import Toggle from '../core/Toggle';

const Wrapper = styled.header`
  display: flex;
  flex-direaction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: #fff;
`

function Header() {
  return (
    <Wrapper>
      <Toggle />
      <HeaderProfile />
    </Wrapper>
  );
}

export default Header;
