import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import Colors from '../../constants/Colors';
import Dimens from '../../constants/Dimens';
import Requests from '../../net/Requests';
import { IQuestion } from '../../net/Requests/Examination/Questions/Get';
import { QuestionType } from '../../pages/ExaminationManagement/Classes/Subjects/Questions/CreateQuestionPage';
import H4 from '../core/h4';
import ListItem from '../core/ListItems';
import P from '../core/P';
import SvgDelete from '../svg/SvgDelete';
import SvgEdit from '../svg/SvgEdit';

export interface IProps {
  question: IQuestion
  onDelete?(uuid: string): any
}

function QuestionListItem({ question, onDelete=() => undefined }: IProps) {
  const { url } = useRouteMatch()

  function onClickDelete() {
    Requests.examination.questions.delete().one({ uuid: question.uuid })
    onDelete(question.uuid)
  }

  return (
    <ListItem>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <H4 style={{
          margin: '24px 0 12px 0'
        }}>Question</H4>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <P style={{ padding: "6px", color: "#fff", background: Colors.ACCENT, borderRadius: Dimens.BORDER_RAD_X }}>{question.duration} sec</P>
          {/* <Link to={`${url}/edit/${question.uuid}`}>
            <SvgEdit style={{ width: 36, height: 36, padding: 6, marginLeft: 6 }} />
          </Link> */}
          <SvgDelete onClick={onClickDelete} style={{ width: 36, height: 36, padding: 6, marginLeft: 6 }} />
        </div>
      </div>
      <span style={{
        fontSize: 12,
        fontWeight: 700,
        color: Colors.PRIMARY_LIGHT
      }}>{question.questionType}</span>
      <div dangerouslySetInnerHTML={{ __html: question.question }}></div>
      {question.questionType === QuestionType.SINGLE_CORRECT? (
        <>
          <H4 style={{
            margin: '12px 0'
          }}>Options</H4>
          <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
            {question.options.map((opt, index) => {
                return <li key={opt.uuid} style={{
                  display: 'flex',
                  flexDirection: 'row',
                  margin: '12px 0',
                  borderRadius: Dimens.BORDER_RAD_X,
                  background: opt.correct? Colors.PRIMARY_LIGHT: Colors.BORDER
                }}>
                  <P style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    margin: 0,
                    padding: 12
                  }}>{index + 1 }</P>
                  <div dangerouslySetInnerHTML={{ __html: opt.option }}></div>
                </li>
              })}
          </ul>
        </>
      ): (
        <></>
      )}
    </ListItem>
  );
}

export default QuestionListItem;
