import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Dimens from '../../constants/Dimens';
import { IAssetModel } from '../../models/asset';
import Requests from '../../net/Requests';
import { IStudent } from '../../net/Requests/Users/Students/Get';
import P from '../core/P';


export interface IProps {
  student: IStudent
  profileImage: IAssetModel
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px;
`

const WrapperImage = styled.div`
  width: 100px;
  height: 100px;
  background: #f0f0f0;
  border-radius: ${Dimens.BORDER_RAD_X2}px;
  overflow: hidden;
  position: relative;
`

const InputFileSelect = styled.input`
  display: none;
`

const ImageView = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`

function ProfileImage({ student, profileImage }: IProps) {

  const [ profileImageUrl, setProfileImageUrl ] = useState(profileImage.primaryUrl)
  const [ loading, setLoading ] = useState(false)
  const [ image, setImage ] = useState<File|null>(null)

  const fileSelectRef = React.useRef<HTMLInputElement|null>(null)
  
  useEffect(() => {
    (async function () {
      if (image) {
        setLoading(true)
        const imgUrl = await Requests.users.students.post().profileImage({
          uuid: student.uuid,
          profileImage: image
        })
        setProfileImageUrl(imgUrl)
        setLoading(false)
      }
    })()
  }, [ image ]);

  return (
    <Wrapper>
      <InputFileSelect type="file" accept=".jpeg, .jpg, .png" ref={fileSelectRef} onChange={ev => ev.target.files? setImage(ev.target.files[0]): undefined} />
      <WrapperImage onClick={() => fileSelectRef.current?.click()}>
        <ImageView src={profileImageUrl} />
        {loading && <LoadingWrapper>
          <P style={{ color: "#fff", textAlign: 'center' }}>Uploading<br/>. . .</P>
        </LoadingWrapper>}
      </WrapperImage>
    </Wrapper>
  );
}

export default ProfileImage;
