import Net from "../../../Net";

export interface IPostScheduleUpdateData {
  uuid: string
  startAt: Date
  endAt: Date
}

export default class PutSchedule {
  public async update(data: IPostScheduleUpdateData) {
    try {
      const res = await Net.put({
        url: "/examination/schedule",
        body: {
          uuid: data.uuid,
          startAt: data.startAt,
          endAt: data.endAt,
        }
      }) as any;
      return res.success
    } catch (error) {
      throw error;
    }
  }
}