import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Form from '../../../../../components/core/Form';
import H3 from '../../../../../components/core/h3';
import InputSubmit from '../../../../../components/core/InputSubmit';
import InputText from '../../../../../components/core/InputText';
import Card from '../../../../../components/layout/Card';
import Requests from '../../../../../net/Requests';

function CreateSectionsPage() {
  const { uuidClass } = useParams<{uuidClass: string}>();
  const history = useHistory();
  
  const [ loading, setLoading ] = useState(false)
  const [ sectionName, setSectionName ] = useState<string>('')
  
  const createSections = async () => {
    setLoading(true)
    await Requests.academics.sections.post().create({
      uuidClass,
      section: sectionName
    });

    history.goBack()
  }

  const header = <>
    <H3>Add section</H3>
  </>

  return (
    <Card header={header} style={{ padding: 12 }}>
      <Form>
        <InputText placeholder="SECTION" value={sectionName} onChange={setSectionName} />
        <InputSubmit loading={loading} onClick={createSections} title="ADD" />
      </Form>
    </Card>
  );
}

export default CreateSectionsPage;
