import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e9e9e9;
  width: 60px;
  height: inherit;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  width: 30px;
  display: flex;
  flex-direction: column;
`

const ToggleLines = styled.span`
  margin: 2px 0;
  height: 4px;
  width: 100%;
  background: #ababab;
  border-radius: 10px;
  `

function Toggle() {
  return (
    <Wrapper>
      <Container>
        <ToggleLines />
        <ToggleLines />
        <ToggleLines />
      </Container>
    </Wrapper>
  );
}

export default Toggle;
