import Net from "../../../Net";

export enum ApprovalStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum MessageLanguage {
  ENGLISH = "ENGLISH",
  UNICODE = "UNICODE",
}

export interface IMessageTemplate {
  senderId: string
  templateId: string
  templateName: string
  templateText: string
  approvalStatus: ApprovalStatus
  language: MessageLanguage
}

export interface IPostmessagesTemplatesGet {
  limit: number
  skip: number
}

export default class GetMessagesTemplates {
  public async get(data: IPostmessagesTemplatesGet) {
    try {
      const res = await Net.get({
        url: "/messages/templates",
        query: {
          "limit": data.limit,
          "skip": data.skip
        }
      }) as any;
      return res.success.templates as Array<IMessageTemplate>
    } catch (error) {
      throw error;
    }
  }
}