import React, { useEffect, useState } from 'react';

import Requests from '../../net/Requests';
import { ISection } from '../../net/Requests/Academics/Sections/Get';
import Dropdown, { Item } from '../core/Dropdown';


export interface IProps {
  selected?: ISection
  uuidClass: string | undefined
  onChange(uuidSection: string): void
}


function DropdownSectionSelect({ selected, uuidClass, onChange }: IProps) {
  
  const [ sections, setSections ] = useState<Array<ISection> | null>(null)

  useEffect(() => {
    if (selected?.uuidClass !== uuidClass) {
      onChange('')
    }
    (async function () {
      if (uuidClass) {
        const secData = await Requests.academics.sections.get().all({ uuidClass })
        setSections(secData)
      }
    })()
  }, [ uuidClass ]);

  const onChangeSection = (section: Array<string>) => {
    onChange(section[0])
  }

  return (
    <Dropdown<ISection> placeholder="SELECT SECTION" style={{
      margin: '12px 0',
      borderRadius: '6px',
    }}
    data={sections}
    selected={selected !== undefined? [{ key: selected.uuid, placeholder: `Section - ${selected.section}` }]: undefined}
    resetKey={uuidClass}
    onChange={onChangeSection}
    renderItem={(item) => {
      return <Item key={item.uuid} value={item.uuid} placeholder={`Section - ${item.section}`} />
    }} />
  );
}

export default DropdownSectionSelect;
