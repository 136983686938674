import React from 'react';
import { v1 as uuidV1 } from "uuid";
import { Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../../../../components/core/PrivateRoute';
import CreateSubjectsPage from './CreateSubjectsPage';
import SubjectsPage from './SubjectsPage';

export interface IProps {
  flagAddNew?: boolean;
  flagQuestions?: boolean;
}

function SubjectsRouter({ flagAddNew = false, flagQuestions = false }: IProps) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path} key={uuidV1()} children={<SubjectsPage flagAddNew={flagAddNew} flagQuestions={flagQuestions} />} />
      <PrivateRoute path={`${path}/add`} children={<CreateSubjectsPage />} />
    </Switch>
  );
}

export default SubjectsRouter;
