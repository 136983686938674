const Dimens = {
  X: 6,
  X2: 12,
  X3: 18,
  X4: 24,
  X5: 30,
  X6: 36,
  X7: 42,
  X8: 48,
  X9: 54,
  X10: 60,

  FONT_H1: 28,
  FONT_H2: 36,
  FONT_H3: 24,
  FONT_H4: 20,
  // FONT_H5: ,
  // FONT_H6: ,
  FONT_P: 16,

  FONT_WEIGHT_100: 100,
  FONT_WEIGHT_200: 200,
  FONT_WEIGHT_300: 300,
  FONT_WEIGHT_400: 400,
  FONT_WEIGHT_500: 500,
  FONT_WEIGHT_600: 600,
  FONT_WEIGHT_700: 700,
  FONT_WEIGHT_800: 800,

  BORDER_RAD_X: 6,
  BORDER_RAD_X2: 12,

  SHADOW_X: "0 8px 16px 0 rgba(0, 0, 0, 0.2)"
}

export default Dimens;