import Net from "../../../Net";

export interface IPostClassCreateData {
  class: string;
}

export default class PostClass {
  public async create(data: IPostClassCreateData) {
    try {
      const res = await Net.post({
        url: "/academics/classes",
        body: {
          class : data.class,
        }
      }) as any;
      return res.success
    } catch (error) {
      throw error;
    }
  }
}