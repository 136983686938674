import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import PrivateRoute from "../../../components/core/PrivateRoute";
import MessagingTemplateAddPage from "./MessagingTemplateAddPage";
import MessagingTemplatesPage from "./MessagingTemplatePage";
import TemplatesSendPage from "./MessagingTemplateSendPage";

function MessagingRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path} children={<MessagingTemplatesPage />} />
      <PrivateRoute
        path={`${path}/add`}
        children={<MessagingTemplateAddPage />}
      />
      <PrivateRoute
        path={`${path}/:templateId/send`}
        children={<TemplatesSendPage />}
      />
      {/* <PrivateRoute path={`${path}/:uuidClass/sections`} key={uuidV1()} children={<SectionsRouter />} /> */}
    </Switch>
  );
}

export default MessagingRouter;
