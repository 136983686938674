import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import H3 from '../../../components/core/h3';
import InputSubmit from '../../../components/core/InputSubmit';
import Card from '../../../components/layout/Card';
import Requests from '../../../net/Requests';


function NoticeAddPage() {
  const history = useHistory()
  const { uuidNotice } = useParams<{uuidNotice: string}>()

  const [ loading, setLoading ] = useState(false)

  const onClickDelete = async () => {
    setLoading(true)
    await Requests.notices.delete().delete({ uuid: uuidNotice })
    setLoading(false)
    history.goBack()
  }

  const header = <>
    <H3>Delete Notice</H3>
  </>

  return (
    <Card header={header} >
      <InputSubmit loading={loading} onClick={onClickDelete} title="Delete Notice" />
    </Card>
  );
}

export default NoticeAddPage;
