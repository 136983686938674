import React, { CSSProperties } from 'react';
import styled from 'styled-components';

export interface IProps {
  style?: CSSProperties;
  size?: number;
  border?: number;
  color?: string;
}

interface ILoadingItemProps {
  size: number;
  border: number;
  color: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  padding: 12px 0;
`

const LoadingItem = styled.div<ILoadingItemProps>`
border-color: #222;
display: inline-block;
width: ${props => props.size}px;
height: ${props => props.size}px;
border-radius: 50%;
border: ${props => props.border}px solid ${props => props.color};
border-bottom-color:transparent;
border-left-color:transparent;
animation: circ-anim 0.7s linear infinite;
@keyframes circ-anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
`

function Loading({ style, size=36, border=2, color="#f44336", }: IProps) {
  return (
    <Wrapper style={style}>
      <LoadingItem size={size} border={border} color={color} />
    </Wrapper>
  );
}

export default Loading;