import React, { useEffect, useState } from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import Button from '../../../../../components/core/Button';
import H3 from '../../../../../components/core/h3';
import List from '../../../../../components/core/List';
import ListItem from '../../../../../components/core/ListItems';
import Loading from '../../../../../components/core/Loading';
import P from '../../../../../components/core/P';
import Card from '../../../../../components/layout/Card';
import Requests from '../../../../../net/Requests';
import { ISection } from '../../../../../net/Requests/Academics/Sections/Get';

function SectionsPage() {
  const { url } = useRouteMatch();
  const { uuidClass } = useParams<{uuidClass: string}>();
  
  const [ loading, setLoading ] = useState<boolean>(true)
  const [ sections, setSsections ] = useState<ISection[]>([])
  
  useEffect(() => {
    (async function () {
      const sect = await Requests.academics.sections.get().all({ uuidClass })
      setSsections(sect)
      setLoading(false)
    })()
  }, [ uuidClass ]);
  
  const subjectsView = sections? (
    sections.map((item: ISection) => {
      return <ListItem key={item.uuid} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <P>Section - {item.section}</P>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <Link to={`${url}/${item.uuid}/students`}>
            <Button style={{ margin: '6px'}} title="Students" />
          </Link>
        </div>
    </ListItem>})
  ): <p>Not Found</p>

  return (
    <Card header={
      <>
        <H3>Sections</H3>
        <Link to={ `${url}/add`} style={{ margin: 12 }}>
          <Button title="Add Section" />
        </Link>
      </>
    }>
      <List>
        {subjectsView}
        {loading? (
          <ListItem>
            <Loading />
          </ListItem>
        ): <></> }
      </List>
    </Card>
  );
}

export default SectionsPage;
