import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import H3 from '../components/core/h3';
import InputPassword from '../components/core/InputPassword';
import InputSubmit from '../components/core/InputSubmit';
import InputText from '../components/core/InputText';
import P from '../components/core/P';
import { useAuth } from '../components/core/ProvideAuth';
import Card from '../components/layout/Card';

const Wrapper = styled.section`
  display: flex;
  flex: 1;
  flex-basis: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const LoginContainer = styled.div`
  display: flex;
  flex-basis: 100%;
  max-width: 600px;
  padding: 48px 12px;
`

function LoginPage() {
  const history = useHistory()
  const auth = useAuth()

  const [ loading, setLoading ] = useState(false)
  const [ username, setUsername ] = useState<string>('')
  const [ password, setPassword ] = useState<string>('')
  
  const onClickLogin = () => {
    setLoading(true)
    auth?.signin({ username, password }, () => history.push("/"))
  }

  return (
    <Wrapper>
      <LoginContainer>
        <Card style={{ padding: "48px 24px"}}>
          <H3>Welcome Back</H3>
          <P style={{ padding: '12px 0'}}>Please enter your username and password</P>
          <InputText placeholder="USERNAME" value={username} onChange={setUsername} />
          <InputPassword placeholder="PASSWORD" value={password} onChange={setPassword} />
          <InputSubmit loading={loading} title="LOGIN"onClick={onClickLogin} />
        </Card>
      </LoginContainer>
    </Wrapper>
  );
}

export default LoginPage;
