import Net from "../../Net";

export interface IPostNoticesCreate {
  title: string
  description: string
  noticeDate?: Date
  asset: File|null
}

export default class PostNotices {
  public async create(data: IPostNoticesCreate) {
    try {
      console.log(JSON.stringify(data.asset, null, 2))
      const body = new FormData()
      body.append("title", data.title)
      body.append("description", data.description)
      if (data.noticeDate) body.append("noticeDate", data.noticeDate.toISOString())
      if (data.asset) body.append("file", data.asset)

      const res = await Net.postMultipart({
        url: "/notices",
        body
      }) as any;
      console.log(res);
      return res.success
    } catch (error) {
      throw error;
    }
  }
}