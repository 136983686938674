import React from 'react';
import { Switch } from 'react-router-dom';
import styled from 'styled-components';
import AcademicsManagementRouter from '../../pages/AcademicsManagement/AcademicsManagementRouter';
import CommunicationManagementRouter from '../../pages/CommunicationManagement/CommunicationManagementRouter';
import ExaminationManagementRouter from '../../pages/ExaminationManagement/ExaminationManagementRouter';
import PrivateRoute from '../core/PrivateRoute';

const Wrapper = styled.main`
  background: #f6f6f6;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 60px 0 0 300px;
  overflow: auto;
`

function Main() {
  return (
    <Wrapper>
      <div style={{
        display: "flex",
        flexBasis: "100%",
        maxWidth: '600px',
        margin: '24px auto',
        padding: '0 12px'
      }}>
        <Switch>
          <PrivateRoute exact path="/">
            <p></p>
          </PrivateRoute>
          <PrivateRoute path="/academics">
            <AcademicsManagementRouter />
          </PrivateRoute>
          <PrivateRoute path="/examination">
            <ExaminationManagementRouter />
          </PrivateRoute>
          <PrivateRoute path="/communication">
            <CommunicationManagementRouter />
          </PrivateRoute>
        </Switch>
      </div>
    </Wrapper>
  );
}

export default Main;
