import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../../../../components/core/PrivateRoute';
import ExaminationResultsSubjectsPage from './ExaminationResultsSubjectsPage';
import ExaminationResultsScheduledRouter from './Scheduled/ExaminationResultsScheduledRouter';

function ExaminationResultsSubjectsRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <ExaminationResultsSubjectsPage />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:uuidSubject/scheduled`} children={<ExaminationResultsScheduledRouter />} />
    </Switch>
  );
}

export default ExaminationResultsSubjectsRouter;
