import React, { CSSProperties, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Colors from "../../constants/Colors";
import Dimens from "../../constants/Dimens";
import Loading from "./Loading";

export interface IProps {
  title?: string;
  style?: CSSProperties;
  fixSize?: boolean;
  loading: boolean;
  disabled?: boolean;
  onClick(): any;
}

interface IContainerProps {
  disabled: boolean;
}

const Container = styled.div<IContainerProps>`
  margin: 12px 0;
  overflow: hidden;
  min-height: 40px;
  background: ${(props) =>
    props.disabled ? Colors.PRIMARY_LIGHT : Colors.PRIMARY};
  border-radius: ${Dimens.BORDER_RAD_X}px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  margin: 0;
`;

const StyledInput = styled.input`
  border: 0;
  padding: 12px 24px;
  margin: 0;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  background: transparent;
  color: #fff;
  text-transform: uppercase;
  font-weight: ${Dimens.FONT_WEIGHT_600};
  border-radius: ${Dimens.BORDER_RAD_X}px;
  text-align: center;
  cursor: pointer;
  outline: none;
`;

function InputSubmit({
  style,
  title,
  loading = false,
  fixSize = true,
  onClick,
  disabled = false,
}: IProps) {
  const refWrapper = useRef() as React.MutableRefObject<HTMLInputElement>;

  const [fixedStyle, setFixedStyle] = useState<CSSProperties>({});

  const onClickButton = (
    evt: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    evt.preventDefault();
    onClick();
  };

  useEffect(() => {
    if (fixSize && refWrapper) {
      setFixedStyle({
        width: refWrapper?.current?.clientWidth,
        height: refWrapper?.current?.clientHeight,
      });
    }
  }, [fixSize]);

  const view = loading ? (
    <Loading size={20} color="#fff" />
  ) : (
    <StyledInput
      type="submit"
      style={style}
      value={title}
      onClick={disabled ? undefined : onClickButton}
    />
  );

  return (
    <Container ref={refWrapper} disabled={disabled} style={fixedStyle}>
      <Wrapper style={style}>{view}</Wrapper>
    </Container>
  );
}

export default InputSubmit;
