import Net from "../../../Net";
import { IQuestion } from "./Get";
import { IQuestionOptions } from "./Post";

export interface IPatchQuestionsData {
  uuid: string
  question: string
  options: Array<IQuestionOptions>
}

export default class PatchQuestions {
  public async one(data: IPatchQuestionsData): Promise<Array<IQuestion>> {
    try {
      const res = await Net.post({
        url: "/examination/questions",
        body: {
          // uuidClass: data.uuidClass,
          // uuidSubject: data.uuidSubject,
          // question: data.question,
          // questionType: data.questionType,
          // duration: data.duration,
          // options: data.options
        }
      }) as any;
      return res.success
    } catch (error) {
      throw error;
    }
  }
}