import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuth } from "./ProvideAuth";

function PrivateRoute({ children, ...rest }: RouteProps) {
  let auth = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return auth?.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }}
    />
  );
}

export default PrivateRoute;