import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import Dimens from '../../constants/Dimens';
import Fonts from '../../constants/Font';

export interface IProps {
  style?: CSSProperties;
  children: ReactNode;
}

const Wrapper = styled.h3`
  display: flex;
  margin: ${Dimens.X4}px 0;
  padding: 0 ${Dimens.X2}px;
  font-size: ${Dimens.FONT_H3}px;
  font-weight: ${Dimens.FONT_WEIGHT_600};
  line-height: 1;
  font-family: ${Fonts.PRIMARY};
`

function H3({ style, children }: IProps) {
  return (
    <Wrapper style={style} >{children}</Wrapper>
  );
}

export default H3;
