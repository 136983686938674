const Colors = {
  PRIMARY: "#3F826D",
  PRIMARY_LIGHT: "#8AC7B3",

  DANGER: "#FC5B5B",

  ACCENT: "#3377FF",
  ACCENT_LIGHT: "#809BFF",

  WARNING_LIGHT: "#FFF4BD",

  TEXT_LIGHT: "#aaa",
  TEXT_DARK: "222",
  BORDER: "rgba(0, 0, 0, 0.1)"

}

export default Colors;