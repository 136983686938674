import Net from "../../Net";

export interface IDeleteNoticesDelete {
  uuid: string
}

export default class DeleteNotices {
  public async delete(data: IDeleteNoticesDelete) {
    try {
      const res = await Net.delete({
        url: "/notices",
        body: {
          uuidNotice: data.uuid
        }
      }) as any;
      console.log(res);
      return res.success
    } catch (error) {
      throw error;
    }
  }
}