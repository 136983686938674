import React, { useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import Button from "../../../components/core/Button";
import H3 from "../../../components/core/h3";
import H4 from "../../../components/core/h4";
import InputSubmit from "../../../components/core/InputSubmit";
import InputText from "../../../components/core/InputText";
import P from "../../../components/core/P";
import Card from "../../../components/layout/Card";
import Colors from "../../../constants/Colors";
import Dimens from "../../../constants/Dimens";
import Requests from "../../../net/Requests";
import { IPostmessagesTemplatesCreate } from "../../../net/Requests/Messages/templates/Post";

function MessagingTemplateAddPage() {
  const { url } = useRouteMatch();

  const [flowId, setFlowId] = useState("");
  const [templateText, setTemplateText] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [variables, setVariables] = useState<Array<string>>([]);

  const onClickAdd = async () => {
    setLoading(true);
    const dataCreateTemplate: IPostmessagesTemplatesCreate = {
      flowId: flowId,
      templateName: templateName,
      templateText: templateText,
    };
    console.log(dataCreateTemplate);
    await Requests.messages.templates.post().create(dataCreateTemplate);
    setLoading(false);
  };

  const onTemplateChange = (newTemplate: string) => {
    setVariablesFromStr(newTemplate);
    setTemplateText(newTemplate);
  };

  const setVariablesFromStr = (str: string) => {
    const variables = str.match(/##([a-z]+)##/gi) || [];
    const setVar = Array.from(new Set(variables));
    setCanSubmit(str.length > 0 && variables?.length === setVar.length);
    setVariables(setVar);
  };

  const header = (
    <>
      <H3>Add Template</H3>
    </>
  );

  return (
    <Card header={header}>
      <P
        style={{
          background: Colors.WARNING_LIGHT,
          borderRadius: Dimens.BORDER_RAD_X,
          marginTop: Dimens.X2,
          lineHeight: 1.2,
        }}
      >
        For dynamic values in the SMS content, you can create variables like{" "}
        <b>##VariableName##</b> or <b>##VAR1##</b> or <b>##VAR2##</b> and can
        pass their values while sending the SMS
      </P>
      <InputText
        value={templateName}
        onChange={setTemplateName}
        placeholder="Template Name"
      />
      <InputText value={flowId} onChange={setFlowId} placeholder="Flow ID" />
      <InputText
        value={templateText}
        onChange={onTemplateChange}
        placeholder="Template text"
        multiline={true}
      />
      <P style={{ textAlign: "right" }}>Characters: {templateText.length}</P>
      {variables.length > 0 ? (
        <div
          style={{
            background: Colors.PRIMARY_LIGHT,
            borderRadius: Dimens.BORDER_RAD_X,
          }}
        >
          <P>
            <strong>Variables Found</strong>
          </P>
          {variables.map((item, index) => (
            <P key={item}>
              <b>{index + 1}.</b> {item}
            </P>
          ))}
        </div>
      ) : (
        <></>
      )}
      <InputSubmit
        disabled={!canSubmit}
        loading={loading}
        onClick={onClickAdd}
        title="Add Template"
      />
    </Card>
  );
}

export default MessagingTemplateAddPage;
