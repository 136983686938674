import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import P from '../core/P';

const Wrapper = styled.section`
  width: 300px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  z-index: 1;
  background: #fff;
`

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const ListItem = styled.li`
  padding: 0;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  &:hover {
    background: #f9f9f9;
  }
`

function Sidebar() {
  return (
    <Wrapper>
      <List>
        {[{
          url: "/examination",
          title: "Examination"
        }, {
          url: "/academics",
          title: "Academics"
        }, {
          url: "/communication",
          title: "Communication"
        }].map( item => <ListItem key={item.url}>
          <Link to={item.url}>
            <P style={{ margin: 0, padding: '18px 24px'}}>{item.title}</P>
          </Link>
        </ListItem>)}
      </List>
    </Wrapper>
  );
}

export default Sidebar;
