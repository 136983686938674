import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import H3 from '../../../../../../components/core/h3';
import Card from '../../../../../../components/layout/Card';
import StudentDetailsInput, { IStudentData } from '../../../../../../components/userManagement/StudentDetailsInput';
import Requests from '../../../../../../net/Requests';

function CreateStudentsPage() {
  const history = useHistory();
  const { uuidClass, uuidSection } = useParams<{uuidClass: string, uuidSection: string}>();

  const createStudent = async (student: IStudentData) => {
    await Requests.users.students.post().create({
      name: student.name,
      phoneNumber: student.phoneNumber,
      uuidClass: uuidClass,
      uuidSection: uuidSection,
      gender: student.gender,
      email: student.email,
      rollNumber: student.rollNumber,
      dateOfBirth: student.dateOfBirth,
      uuidSubjects: student.subjects
    });
    history.goBack()
  }

  const header = <>
    <H3>Add Student</H3>
  </>

  return (
    <Card header={header} style={{ padding: 12 }}>
      <StudentDetailsInput submitTitle="ADD" onClick={createStudent} fields={{ CLASS: false, SECTION: false }}  />
    </Card>
  );
}

export default CreateStudentsPage;
