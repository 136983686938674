import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import H3 from '../../components/core/h3';
import List from '../../components/core/List';
import ListItem from '../../components/core/ListItems';
import P from '../../components/core/P';
import Card from '../../components/layout/Card';

function AcademicsPage() {
  const { url } = useRouteMatch();

  return (
    <Card header={
      <H3>Academics</H3>
    }>
      <List>
        {[{
          url: `${url}/classes`,
          title: "Classes"
        // }, {
        //   url: `${url}/sessions`,
        //   title: "Sessions"
        }].map( item => <ListItem key={item.url}>
          <Link to={item.url}>
            <P>{item.title}</P>
          </Link>
        </ListItem>)}
      </List>
    </Card>
  );
}

export default AcademicsPage;
