import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import H3 from '../../../components/core/h3';
import InputDate from '../../../components/core/InputDate';
import InputFile from '../../../components/core/InputFile';
import InputSubmit from '../../../components/core/InputSubmit';
import InputText from '../../../components/core/InputText';
import Card from '../../../components/layout/Card';
import Requests from '../../../net/Requests';


function NoticeAddPage() {
  const { url } = useRouteMatch()
  const history = useHistory()

  const [ title, setTitle ] = useState("")
  const [ description, setDescription ] = useState("")
  const [ asset, setAsset ] = useState<File|null>(null)
  const [ loading, setLoading ] = useState(false)
  const [ noticeDate, setNoticeDate ] = useState<Date>()

  const onClickAdd = async () => {
    setLoading(true)
    await Requests.notices.post().create({ title, description, asset, noticeDate })
    setLoading(false)
    history.goBack()
  }

  const header = <>
    <H3>Add Notice</H3>
  </>

  return (
    <Card header={header} >
      <InputText value={title} onChange={setTitle} placeholder="Title" />
      <InputText value={description} onChange={setDescription} placeholder="Description" multiline={true} />
      <InputDate value={noticeDate} onChange={setNoticeDate} />
      <InputFile onChange={setAsset} />
      
      <InputSubmit disabled={title.length === 0 || description.length === 0} loading={loading} onClick={onClickAdd} title="Add Notice" />
    </Card>
  );
}

export default NoticeAddPage;
