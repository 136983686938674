import React from 'react';
import styled from 'styled-components';
import Header from '../components/layout/Header';
import Main from '../components/layout/Main';
import Sidebar from '../components/layout/Sidebar';

const Wrapper = styled.section`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
`

function HomePage() {
  return (
    <Wrapper>
      <Header />
      <Sidebar />
      <Main />
    </Wrapper>
  );
}

export default HomePage;
