import React, { useEffect, useState } from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import Button from '../../../../../components/core/Button';
import H3 from '../../../../../components/core/h3';
import List from '../../../../../components/core/List';
import ListItem from '../../../../../components/core/ListItems';
import Loading from '../../../../../components/core/Loading';
import P from '../../../../../components/core/P';
import Card from '../../../../../components/layout/Card';
import Requests from '../../../../../net/Requests';
import { ISubject } from '../../../../../net/Requests/Academics/Subjects/Get';

export interface IProps {
  flagAddNew?: boolean;
  flagQuestions?: boolean;
}

function ExaminationResultsSubjectsPage({ flagAddNew = false }: IProps) {
  const { url, path } = useRouteMatch();
  const { uuidClass } = useParams<{uuidClass: string}>();
  
  const [ loading, setLoading ] = useState<boolean>(true)
  const [ subjects, setSubjects ] = useState<ISubject[]>([])
  
  useEffect(() => {
    (async function () {
      const subj = await Requests.academics.subjects.get().all({ uuidClass })
      setSubjects(subj)
      setLoading(false)
    })()
  }, [ uuidClass ])
  
  const subjectsView = subjects? (
    subjects.map((item: ISubject, index: number) => {
      return <ListItem key={item.uuid} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <P>{index + 1} - {item.subject}</P>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <Link to={ `${url}/${item.uuid}/scheduled`} style={{ margin: 6 }}>
            <Button title="Schedule" />
          </Link>
        </div>
    </ListItem>})
  ): <p>Not Found</p>

  const header =  <>
    <H3>Subjects</H3>
  </>

  return (
    <Card header={header}>
      <List>
        {subjectsView}
        {loading? (
          <ListItem>
            <Loading />
          </ListItem>
        ): <></> }
      </List>
    </Card>
  );
}

export default ExaminationResultsSubjectsPage;
