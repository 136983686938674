import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import Button from '../../../../components/core/Button';
import H3 from '../../../../components/core/h3';
import List from '../../../../components/core/List';
import ListItem from '../../../../components/core/ListItems';
import Loading from '../../../../components/core/Loading';
import P from '../../../../components/core/P';
import Card from '../../../../components/layout/Card';
import Requests from '../../../../net/Requests';
import { IClass } from '../../../../net/Requests/Academics/Classes/Get';

function ExaminationResultsClassesPage() {
  const { path } = useRouteMatch();

  const [ loading, setLoading ] = useState(true)
  const [ classes, setClasses ] = useState<IClass[]>([])
  
  useEffect(() => {
    (async function () {
      const classes = await Requests.academics.classes.get().all();
      setClasses(classes);
      setLoading(false)
    })()
  }, []);

  const classView = classes? (
    classes.map((item) => {
      return <ListItem key={item.uuid} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <P>Class - {item.class}</P>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <Link to={`${path}/${item.uuid}/subjects`}>
            <Button style={{ margin: '6px'}} title="Subjects" />
          </Link>
        </div>
      </ListItem>
    })
  ): ( <p>Not Found</p> )

  const loadingView = loading? (
    <ListItem>
      <Loading />
    </ListItem>
  ): <></>

  const header = <>
    <H3>Classes</H3>
  </>

  return (
    <Card header={header}>
      <List>
        {classView}
        {loadingView}
      </List>
    </Card>
  );
}

export default ExaminationResultsClassesPage;
