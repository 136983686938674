import DeleteClasses from "./Requests/Academics/Classes/Delete";
import GetClasses from "./Requests/Academics/Classes/Get";
import PostClasses from "./Requests/Academics/Classes/Post";
import PutClasses from "./Requests/Academics/Classes/Put";
import GetSections from "./Requests/Academics/Sections/Get";
import PostSections from "./Requests/Academics/Sections/Post";
import PutSections from "./Requests/Academics/Sections/Put";
import DeleteSections from "./Requests/Academics/Subjects/Delete";
import DeleteSubjects from "./Requests/Academics/Subjects/Delete";
import GetSubjects from "./Requests/Academics/Subjects/Get";
import PostSubjects from "./Requests/Academics/Subjects/Post";
import PutSubjects from "./Requests/Academics/Subjects/Put";
import PostAuthLogin from "./Requests/Auth/Login/Post";
import DeleteQuestions from "./Requests/Examination/Questions/Delete";
import GetQuestions from "./Requests/Examination/Questions/Get";
import PatchQuestions from "./Requests/Examination/Questions/Patch";
import PostQuestions from "./Requests/Examination/Questions/Post";
import GetStudents from "./Requests/Users/Students/Get";
import PutStudents from "./Requests/Users/Students/Put";
import PostStudents from "./Requests/Users/Students/Post";
import GetSchedule from "./Requests/Examination/Schedule/Get";
import PostSchedule from "./Requests/Examination/Schedule/Post";
import PutSchedule from "./Requests/Examination/Schedule/Put";
import GetSessions from "./Requests/Examination/Sessions/Get";
import GetResults from "./Requests/Examination/Result/Get";
import PostMessagesTemplates from "./Requests/Messages/templates/Post";
import GetMessagesTemplates from "./Requests/Messages/templates/Get";
import GetNotices from "./Requests/Notices/Get";
import PostNotices from "./Requests/Notices/Post";
import DeleteNotices from "./Requests/Notices/Delete";
import PostMessagesMessages from "./Requests/Messages/messages/Post";

export default class Requests {
  public static auth = {
    login: {
      post() {
        return new PostAuthLogin();
      },
    },
    signup: {},
  };

  public static users = {
    students: {
      get() {
        return new GetStudents();
      },
      post() {
        return new PostStudents();
      },
      put() {
        return new PutStudents();
      },
    },
  };

  public static academics = {
    subjects: {
      get() {
        return new GetSubjects();
      },
      post() {
        return new PostSubjects();
      },
      put() {
        return new PutSubjects();
      },
      delete() {
        return new DeleteSubjects();
      },
    },
    classes: {
      get() {
        return new GetClasses();
      },
      post() {
        return new PostClasses();
      },
      put() {
        return new PutClasses();
      },
      delete() {
        return new DeleteClasses();
      },
    },
    sections: {
      get() {
        return new GetSections();
      },
      post() {
        return new PostSections();
      },
      put() {
        return new PutSections();
      },
      delete() {
        return new DeleteSections();
      },
    },
  };

  public static examination = {
    questions: {
      get() {
        return new GetQuestions();
      },
      post() {
        return new PostQuestions();
      },
      patch() {
        return new PatchQuestions();
      },
      delete() {
        return new DeleteQuestions();
      },
    },
    schedule: {
      get() {
        return new GetSchedule();
      },
      post() {
        return new PostSchedule();
      },
      put() {
        return new PutSchedule();
      },
    },
    sessions: {
      get() {
        return new GetSessions();
      },
    },
    results: {
      get() {
        return new GetResults();
      },
    },
  };

  public static notices = {
    get() {
      return new GetNotices();
    },
    post() {
      return new PostNotices();
    },
    delete() {
      return new DeleteNotices();
    },
  };

  public static messages = {
    templates: {
      get() {
        return new GetMessagesTemplates();
      },
      post() {
        return new PostMessagesTemplates();
      },
    },

    messages: {
      post() {
        return new PostMessagesMessages();
      },
    },
  };
}
