import React, { useEffect, useState } from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import Button from '../../../../../components/core/Button';
import H3 from '../../../../../components/core/h3';
import List from '../../../../../components/core/List';
import ListItem from '../../../../../components/core/ListItems';
import Loading from '../../../../../components/core/Loading';
import QuestionListItem from '../../../../../components/examinationManagement/QuestionListItems';
import Card from '../../../../../components/layout/Card';
import Requests from '../../../../../net/Requests';
import { IQuestion } from '../../../../../net/Requests/Examination/Questions/Get';

function ExaminationQuestionsPage() {
  const { url } = useRouteMatch();
  const { uuidClass, uuidSubject } = useParams<{uuidClass: string, uuidSubject: string}>();
  
  const [ loading, setLoading ] = useState(true)
  const [ questions, setQuestions ] = useState<Array<IQuestion>>([])

  useEffect(() => {
    (async function () {
      const ques = await Requests.examination.questions.get().all({
        uuidClass,
        uuidSubject
      });
      setQuestions(ques);
      setLoading(false)
    })()
  }, [ uuidClass, uuidSubject ]);

  const onQuestionDelete = (uuid: string) => {
    const remainingQuestions = questions.filter((item) => item.uuid !== uuid)
    setQuestions(remainingQuestions)
  }
  
  const questionsView = questions && questions.length? (
    questions.map((item: IQuestion) => <QuestionListItem key={item.uuid} question={item} onDelete={onQuestionDelete} />)
  ): ( <p>Not found</p> )

  const header = <>
    <H3>Questions {questions?.length? `(${questions.length})`: ""}</H3>
    <Link to={ `${url}/add`} style={{ margin: "0 12px"}}>
      <Button title="Add Question" />
    </Link>
  </>

  return (
    <Card header={header}>
      <List>
        {questionsView}
        {loading? (
          <ListItem><Loading /></ListItem>
        ): <></>}
      </List>
    </Card>
  );
}

export default ExaminationQuestionsPage;
