import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import Button from '../../components/core/Button';
import H3 from '../../components/core/h3';
import List from '../../components/core/List';
import ListItem from '../../components/core/ListItems';
import Loading from '../../components/core/Loading';
import P from '../../components/core/P';
import Card from '../../components/layout/Card';
import Requests from '../../net/Requests';
import { IMessageTemplate } from '../../net/Requests/Messages/templates/Get';


function CommunicationManagementPage() {
  const { url } = useRouteMatch();


  const header = <>
    <H3>Communication</H3>
  </>

  return (
    <Card header={header} >
      <List>
        <ListItem>
          <Link to={`${url}/notices`}>
            <P>Notices</P>
          </Link>
        </ListItem>
        <ListItem>
          <Link to={`${url}/messaging`}>
            <P>Messages</P>
          </Link>
        </ListItem>
      </List>
    </Card>
  );
}

export default CommunicationManagementPage;
