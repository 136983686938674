import React from 'react';
import { v1 as uuidV1 } from "uuid";
import { Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../components/core/PrivateRoute';
import AcademicsPage from './AcademicsPage';
import ClassesRouter from './Sessions/Classes/ClassesRouter';

function AcademicsManagementRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <AcademicsPage />
      </PrivateRoute>
      <PrivateRoute path={`${path}/classes`} key={uuidV1()}>
        <ClassesRouter flagCanAdd flagSections flagSubjects />
      </PrivateRoute>
      <PrivateRoute path={`${path}/sessions`}>
        <p>Session</p>
      </PrivateRoute>
    </Switch>
  );
}

export default AcademicsManagementRouter;
