import React, { CSSProperties, ReactNode } from 'react';
import styled from "styled-components";
import Colors from '../../constants/Colors';
import Dimens from '../../constants/Dimens';

export interface IProps {
  containerStyle?: CSSProperties;
  style?: CSSProperties;
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  border: 1px solid ${Colors.BORDER};
  background: #fff;
  border-radius: ${Dimens.BORDER_RAD_X2}px;
  flex-direction: column;
`

const MainWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  padding: 0 12px;
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${Colors.BORDER};
`

const FooterWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  border-top: 1px solid ${Colors.BORDER};
`

function Card({ style, children, header, footer, containerStyle }: IProps) {

  const hreaderView = header? <HeaderWrapper>{header}</HeaderWrapper>: <></>
  const footerView = header? <FooterWrapper>{footer}</FooterWrapper>: <></>

  return (
    <StyledWrapper style={containerStyle}>
      {hreaderView}
      <MainWrapper style={style}>{children}</MainWrapper>
      {footerView}
    </StyledWrapper>
  );
}

export default Card;
