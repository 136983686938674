import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Form from '../../../../../components/core/Form';
import H3 from '../../../../../components/core/h3';
import InputSubmit from '../../../../../components/core/InputSubmit';
import InputText from '../../../../../components/core/InputText';
import Card from '../../../../../components/layout/Card';
import Requests from '../../../../../net/Requests';

function CreateSubjectsPage() {
  const { uuidClass } = useParams<{uuidClass: string}>();
  const history = useHistory();
  
  const [ loading, setLaoding ] = useState(false)
  const [ subject, setSubject ] = useState<string>('')
  
  const createSubjects = async () => {
    setLaoding(true)
    await Requests.academics.subjects.post().create({
      uuidClass,
      subject: subject
    });

    history.goBack()
  }

  const header = <>
    <H3>Add Subject</H3>
  </>

  return (
    <Card header={header}>
      <Form>
        <InputText placeholder="SUBJECT" value={subject} onChange={setSubject} />
        <InputSubmit loading={loading} onClick={createSubjects} title="ADD" />
      </Form>
    </Card>
  );
}

export default CreateSubjectsPage;
