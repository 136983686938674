import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import PrivateRoute from '../../../../../components/core/PrivateRoute';
import CreateQuestionPage from './CreateQuestionPage';
import ExaminationQuestionsPage from './ExaminationQuestionsPage';

function ExaminationQuestionsRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <ExaminationQuestionsPage />
      </PrivateRoute>
      <PrivateRoute path={`${path}/add`}>
        <CreateQuestionPage />
      </PrivateRoute>
    </Switch>
  );
}

export default ExaminationQuestionsRouter;
