import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Form from '../../../../components/core/Form';
import H3 from '../../../../components/core/h3';
import InputSubmit from '../../../../components/core/InputSubmit';
import InputText from '../../../../components/core/InputText';
import Card from '../../../../components/layout/Card';
import Requests from '../../../../net/Requests';

function CreateClassPage() {
  const history = useHistory();
  
  const [ loading, setLoading ] = useState(false)
  const [ className, setClassName ] = useState<string>('')
  
  const createClass = async () => {
    setLoading(true)
    await Requests.academics.classes.post().create({
      class: className
    });

    history.goBack();
  }

  const header = <>
    <H3>Add Class</H3>
  </>

  return (
    <Card header={header}>
      <Form>
        <InputText placeholder="CLASS" value={className} onChange={setClassName} />
        <InputSubmit loading={loading} onClick={createClass} title="ADD" />
      </Form>
    </Card>
  );
}

export default CreateClassPage;
