import React, { useEffect, useState } from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import Button from '../../../../../../components/core/Button';
import H3 from '../../../../../../components/core/h3';
import List from '../../../../../../components/core/List';
import ListItem from '../../../../../../components/core/ListItems';
import Loading from '../../../../../../components/core/Loading';
import P from '../../../../../../components/core/P';
import Card from '../../../../../../components/layout/Card';
import Requests from '../../../../../../net/Requests';
import { ISchedule } from '../../../../../../net/Requests/Examination/Schedule/Get';

function ExaminationResultsScheduledPage() {
  const { url } = useRouteMatch();
  const { uuidClass, uuidSubject } = useParams<{uuidClass: string, uuidSubject: string}>();
  
  const [ loading, setLoading ] = useState<boolean>(true)
  const [ shcedules, setSchedules ] = useState<ISchedule[]>([])
  
  useEffect(() => {
    (async function () {
      const schd = await Requests.examination.schedule.get().all({ uuidClass, uuidSubject })
      setSchedules(schd)
      setLoading(false)
    })()
  }, [ uuidClass ])
  
  const subjectsView = shcedules? (
    shcedules.map((item: ISchedule, index: number) => {
      return <ListItem key={item.uuid} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <P><strong>Start At - </strong>{item.startAt.toLocaleDateString()} {item.startAt.toLocaleTimeString()}</P>
          <P><strong>End At - </strong>{item.endAt.toLocaleDateString()} {item.endAt.toLocaleTimeString()}</P>
          <P><strong>Freedom Level - </strong>{item.freedomLevel === 10? "Relaxed": "Hard"}</P>
        </div>
        <div>
          <Link to={ `${url}/${item.uuid}/students`} >
            <Button title="Students" />
          </Link>
        </div>
    </ListItem>})
  ): <p>Not Found</p>

  const header = <>
    <H3>Scheduled</H3>
  </>

  return (
    <Card header={header}>
      <List>
        {subjectsView}
        {loading? (
          <ListItem>
            <Loading />
          </ListItem>
        ): <></> }
      </List>
    </Card>
  );
}

export default ExaminationResultsScheduledPage;
